import {
    MSProductWithFullDetail,
    ProductRelate,
    getProductRelateWithArticle,
    getRelatedProduct,
} from "@/util/query/product-query";
import MaxWidthWrapper from "../maxwidthwrapper";
import { getTranslation } from "@/app/i18n";
import { auth } from "@/auth";
import { CUSTOMER_LEVEL } from "@prisma/client";
import { getUser } from "@/actions";
import { useTranslation } from "@/app/i18n/client";
import { useEffect, useState } from "react";
import { useCart } from "@/lib/providers/cart-provider";
import ProductRelatedList from "../products/product-related-list";
import { getRelatedProductInCart } from "@/actions/cart";
import { shuffle } from "@/util/util";

interface Props {
    level: CUSTOMER_LEVEL;
}

const ProductRelatedInCart = ({ level }: Props) => {
    const { loc, t } = useTranslation();
    const { cart } = useCart();
    const [related, setRelated] = useState<MSProductWithFullDetail[]>([]);    

    useEffect(() => {
        const getRelatedProducts = async () => {
            if (cart && cart.saleItems.length > 0) {
                const handles = cart.saleItems.map((x) => x.msProductHandle);
                const products = await getRelatedProductInCart(handles, loc);
                const removeDup = products.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
                const finalRealted = removeDup.filter((i) => handles.indexOf(i.handle) < 0);
                const shuffleProducts = shuffle(finalRealted).slice(0,8);
                setRelated(shuffleProducts);
            }
        };
        getRelatedProducts();
    }, []);

    if (related.length === 0) {
        return null;
    }

    return (
        <MaxWidthWrapper className="py-12">
            <div className="flex flex-col items-center gap-12">
                <h2 className="text-3xl font-medium text-c2">{t("product.related_product")}</h2>
                <ProductRelatedList products={related} level={level} />
            </div>
        </MaxWidthWrapper>
    );
};

export default ProductRelatedInCart;
