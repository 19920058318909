"use client";

import { XCircle } from "lucide-react";
import React, { FormEvent, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useTranslation } from "@/app/i18n/client";
import { useCart } from "@/lib/providers/cart-provider";
import Image from "next/image";

const CartCoupon = () => {
    const { t } = useTranslation();
    const [coupon, setCoupon] = useState<string>("");
    const { cart, isUpdating, addCoupons, removeCoupon, numItemsInCart } = useCart();
    const [error, setError] = useState<string | null | undefined>();

    const codes = (cart?.discountRemarks ?? []).filter((code) => code);

    const handleAddCouponCode = async (e: FormEvent<HTMLFormElement>, text: string) => {
        e.preventDefault();
        
        if (coupon.toLowerCase().startsWith("bd") && numItemsInCart() > 1) {            
            setError(t("cart.coupon_bd_use_for_1_items"));
            return;
        }
        setError(undefined);
        try {
            await addCoupons(coupon);
            setCoupon("");
        } catch (error: any) {
            setError(error?.message);
        }        
    };

    const handleRemoveCode = async (index: number) => {
        await removeCoupon(index);
    };

    const handleOnChangeText = (text: string) => {
        setCoupon(text);

        if (!text) {
            setError(undefined);
        }
        if (!text.toLowerCase().startsWith("bd")) {
            setError(undefined);
        }
    };

    return (
        <div className="p-5 border rounded-lg text-c4">
            <div className="flex flex-col gap-4">
                <div className="flex gap-2 items-center">
                    <Image src="/assets/icons/icon-coupon-code.svg" alt="couponcode" width={35} height={35} />
                    <h5>{t("checkout.coupon_code")}</h5>
                </div>
                <form className="flex flex-col gap-4" onSubmit={(e) => handleAddCouponCode(e, coupon)}>
                    <Input
                        value={coupon}
                        onChange={(e) => handleOnChangeText(e.target.value)}
                        className="form-input"
                        placeholder={t("checkout.input_placeholder_coupon_code")}
                    />
                    {error && (
                        <div>
                            <p className="text-sm font-light text-error">{error}</p>
                        </div>
                    )}
                    {codes.length > 0 && (
                        <div className="flex flex-wrap gap-2 ">
                            {codes.map((code, index) => (
                                <div
                                    key={index}
                                    className="bg-coupon text-coupon-foreground border border-coupon-foreground px-3 py-1 rounded-full flex items-center gap-2">
                                    <p className="text-sm font-light">{code}</p>
                                    <button type="button" onClick={() => handleRemoveCode(index)}>
                                        <XCircle size={16} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    <Button
                        disabled={coupon === ""}
                        isLoading={isUpdating}
                        type="submit"
                        variant="outline"
                        className="w-[108px] h-9 text-sm font-normal">
                        {t("checkout.btn_use_coupon")}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default CartCoupon;
