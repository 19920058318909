"use client";

import Image from "next/image";
import { AspectRatio } from "../ui/aspect-ratio";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { ChevronLeft, ChevronRight } from "lucide-react";
import CarouselPageControl from "../controls/carousel-page-control";
import ProductImageFullscreen from "./product-image-full-screen";

interface Props {
    children?: React.ReactNode;
    imagesUrl: string[];
    currentImageUrl?: string | null;
}

const ImageThumbnail = ({ url, onClick, selected }: { url: string; onClick?: () => void; selected?: boolean }) => {
    return (
        <AspectRatio ratio={1} className={cn("bg-grayc4 rounded-sm p-2", selected && "border-2 border-c2")}>
            <Image
                src={url}
                width={80}
                height={80}
                alt="thumbnail"
                className="w-full h-full object-contain hover:cursor-pointer"
                onClick={onClick}
                unoptimized
            />
        </AspectRatio>
    );
};

const ProductImageGallery = ({ children, imagesUrl, currentImageUrl }: Props) => {
    const [api, setApi] = useState<CarouselApi>();
    const [apiForBottomThumbnail, setApiForBottomThumbnail] = useState<CarouselApi>();
    const [apiForLeftThumbnail, setApiForLeftThumbnail] = useState<CarouselApi>();

    const index = imagesUrl.findIndex((i) => i === currentImageUrl);    
    const [selectedIndex, setSelectedIndex] = useState<number>(index < 0 ? 0 : index);

    useEffect(() => {
        const index = imagesUrl.findIndex((i) => i === currentImageUrl);
        handleSelectedThumbnail(index);
    }, [currentImageUrl]);
    
    useEffect(() => {
        if (!api) {
            return;
        }

        handleSelectedThumbnail(index);

        api.on("select", () => {
            setSelectedIndex(api.selectedScrollSnap());
            apiForBottomThumbnail?.scrollTo(api.selectedScrollSnap());
            apiForLeftThumbnail?.scrollTo(api.selectedScrollSnap());
        });
    }, [api]);

    const handleSelectedThumbnail = (index: number) => {
        if (!api) {            
            return;
        }
        setSelectedIndex(index);
        api.scrollTo(index);

        if (index < selectedIndex && index > 0) {
            apiForBottomThumbnail?.scrollTo(index - 1);
            apiForLeftThumbnail?.scrollTo(index - 1);
        } else {
            apiForBottomThumbnail?.scrollTo(index);
            apiForLeftThumbnail?.scrollTo(index);
        }
    };

    const handleOnNext = () => {
        if (selectedIndex + 1 < imagesUrl.length) {
            handleSelectedThumbnail(selectedIndex + 1);
        }
    };

    const handleOnPrevious = () => {
        if (selectedIndex > 0) {
            handleSelectedThumbnail(selectedIndex - 1);
        }
    };

    if (!imagesUrl || imagesUrl.length === 0) {
        return <div>No Image</div>;
    }

    return (
        <div className="flex gap-5 w-full">
            <div className="w-24 xl:flex hidden max-h-[570px] h-full flex-shrink flex-grow-0 overflow-scroll no-scrollbar">
                <Carousel
                    className="w-full"
                    setApi={setApiForLeftThumbnail}
                    opts={{
                        dragFree: true,
                        containScroll: "trimSnaps",
                    }}
                    orientation="vertical">
                    <CarouselContent>
                        {imagesUrl.map((url, index) => (
                            <CarouselItem key={index}>
                                <ImageThumbnail
                                    url={url}
                                    onClick={() => handleSelectedThumbnail(index)}
                                    selected={index === selectedIndex}
                                />
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </Carousel>
            </div>

            <div className="flex flex-col w-full">
                <div className="relative">
                    <Carousel className="w-full" setApi={setApi}>
                        <CarouselContent>
                            {imagesUrl.map((url, index) => (
                                <CarouselItem key={index}>
                                    <AspectRatio ratio={1} className="bg-grayc4 md:rounded-sm rounded-none ">                                        
                                        <ProductImageFullscreen className="h-full w-full object-contain" imageUrl={imagesUrl[index]} />
                                    </AspectRatio>
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                    </Carousel>
                    <CarouselPageControl
                        className="absolute bottom-5 right-5 flex gap-4"
                        onNext={handleOnNext}
                        onPrevious={handleOnPrevious}
                        disabledNext={selectedIndex === imagesUrl.length - 1}
                        disabledPrevious={selectedIndex === 0}
                    />
                </div>

                <div className="xl:hidden flex mt-5 mx-2 md:mx-0">
                    <Carousel
                        className="w-full"
                        setApi={setApiForBottomThumbnail}
                        opts={{
                            align: "start",
                            dragFree: true,
                            containScroll: "trimSnaps",
                        }}>
                        <CarouselContent className="">
                            {imagesUrl.map((url, index) => (
                                <CarouselItem key={index} className="basis-24">
                                    <div className="">
                                        <ImageThumbnail
                                            url={url}
                                            onClick={() => handleSelectedThumbnail(index)}
                                            selected={index === selectedIndex}
                                        />
                                    </div>
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                    </Carousel>
                </div>
                {children}
            </div>
        </div>
    );
};

export default ProductImageGallery;
