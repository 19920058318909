"use client";

import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import Link from "next/link";
import Autoplay from "embla-carousel-autoplay";
import { AdsBannerComponentWithItems } from "@/util/query/component-query";
import Image from "next/image";
import { getLinkTarget } from "@/util/util";

type Props = {
    adsBannerComponent: AdsBannerComponentWithItems;
};

export const AdsSectionCarousel = ({ adsBannerComponent }: Props) => {
    return (
        <Carousel
            opts={{
                active: adsBannerComponent.items.length > 1 ? true : false,
                loop: adsBannerComponent.items.length > 1 ? true : false,
            }}
            plugins={
                adsBannerComponent.autoSlide
                    ? [Autoplay({ playOnInit: true, delay: Math.max(adsBannerComponent.slideDelay, 2000) })]
                    : []
            }>
            <CarouselContent>
                {adsBannerComponent.items.map((item) => {
                    return (
                        item.attributes?.desktopImageUrl && (
                            <CarouselItem key={item.id}>
                                <Link
                                    href={item.attributes.linkUrl}
                                    className="relative"
                                    target={getLinkTarget(item.linkTarget)}>
                                    <Image
                                        src={item.attributes.desktopImageUrl}
                                        unoptimized
                                        width="1180"
                                        height="100"
                                        alt={item.attributes.imageAlt}
                                        className="w-full hidden sm:block"
                                    />
                                    <Image
                                        src={item.attributes.mobileImageUrl ?? item.attributes.desktopImageUrl}
                                        unoptimized
                                        width="370"
                                        height="76"
                                        alt={item.attributes.imageAlt}
                                        className="w-full block sm:hidden"
                                    />
                                </Link>
                            </CarouselItem>
                        )
                    );
                })}
            </CarouselContent>
        </Carousel>
    );
};

export default AdsSectionCarousel;
