"use client";
import { DateToFormatWithTimeZone } from "@/util/date-format";
import { Card } from "@nextui-org/react";
import { CardContent } from "../ui/card";
import { useTranslation } from "@/app/i18n/client";
import Image from "next/image";
import Link from "next/link";
import { cn } from "@/lib/utils";

export const ArticleCard = ({
    article,
    pathPrefix = "/blogs/editorial",
    display,
}: {
    article: any;
    pathPrefix?: string;
    display?: "CARD" | "DEFAULT";
}) => {
    const { loc } = useTranslation();

    const displayType = display ?? "DEFAULT";

    return (
        <Card className="w-fit max-w-96 rounded-lg p-0">
            <CardContent className="w-fit p-0">
                <Link href={`${pathPrefix}/${article.handle}`}>
                    <div className="w-fit p-0 flex flex-col items-center h-full rounded-lg overflow-hidden">
                        <Image
                            src={article.imageUrl}
                            alt={article.attributes?.title}
                            width={388}
                            height={200}
                            className={cn(
                                "object-cover aspect-[19/10]",
                                displayType === "DEFAULT" ? "rounded-lg" : "rounded-t-lg",
                            )}
                            unoptimized
                            onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = "https://dummyimage.com/388x200/e9e9e9/e9e9e9.jpg";
                            }}
                        />

                        <div className="flex flex-col items-start bg-white w-full h-44">
                            <div
                                className={cn(
                                    "flex flex-col justify-between h-full",
                                    displayType === "DEFAULT" ? "py-5" : "p-5",
                                )}>
                                <div className="flex flex-col w-full text-c4 gap-2">
                                    <h3 className="text-[18px] font-normal first-line:text-wrap text-ellipsis line-clamp-2 hover:text-c2">
                                        {article.attributes?.title}
                                    </h3>
                                    <p className="text-sm font-light text-wrap text-ellipsis line-clamp-2 ">
                                        {article.attributes?.meta_description}
                                    </p>
                                </div>

                                <p className="text-sm font-light text-grayc3 text-wrap text-ellipsis line-clamp-1">
                                    {DateToFormatWithTimeZone(article.publishedAt, loc, "d MMMM yyyy")}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </CardContent>
        </Card>
    );
};
