"use client";

import { ChevronLeftCircle, ChevronRightCircle } from "lucide-react";
import { useTranslation } from "@/app/i18n/client";
import { updateSearchParams } from "@/util/util";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "@/components/ui/select";
import { cn } from "@/lib/utils";

interface PageControlProps {
    currentPage: number;
    maxPage: number;
}

const PageControl = ({ currentPage, maxPage }: PageControlProps) => {
    const { t } = useTranslation();
    const pages = new Array(maxPage).fill(null).map((_, i) => i + 1);

    const handleNext = () => {
        if (currentPage + 1 <= maxPage) {
            const path = updateSearchParams("page", `${currentPage + 1}`);
            window.location.href = path;
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            const path = updateSearchParams("page", `${currentPage - 1}`);
            window.location.href = path;
        }
    };

    const handleOnValueChanged = (value: string) => {
        const selectedPage = +value;
        const path = updateSearchParams("page", `${selectedPage}`);
        window.location.href = path;
    };

    return (
        <div className="flex flex-row justify-center items-center gap-4 my-6 text-c4 text-x w-full">
            <button disabled={currentPage <= 1} onClick={handlePrevious} className="disabled:text-grayc3">
                <ChevronLeftCircle className="w-7 h-7" />
            </button>
            {pages.length <= 1 && (
                <p className="text-lg font-normal">{t("product_list.page_current_to_max", { current: currentPage, max: maxPage })}</p>
            )}
            {pages.length > 1 && (
                <Select value={`${currentPage}`} onValueChange={handleOnValueChanged}>
                    <SelectTrigger
                        className={cn("bg-transparent border-0 w-auto text-2xl px-0 whitespace-nowrap gap-2")}>
                        <p className="text-lg font-normal">{t("product_list.page_current_to_max", { current: currentPage, max: maxPage })}</p>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {pages.map((item) => (
                                <SelectItem key={item} value={`${item}`}>
                                    {item}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            )}
            <button disabled={currentPage === maxPage} onClick={handleNext} className="disabled:text-grayc3">
                <ChevronRightCircle className="w-7 h-7" />
            </button>
        </div>
    );
};

export default PageControl;
