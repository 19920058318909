"use client";

import { useTranslation } from "@/app/i18n/client";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

interface Props {
    className?: string;
    sortDataSource: string[];
    sortDefault: string;
}

const OrderSelection = ({ className, sortDataSource, sortDefault }: Props) => {
    const router = useRouter();
    const pathName = usePathname();
    const searchParams = useSearchParams();
    const sort = searchParams.get("sort_by") ?? sortDefault;
    const { t } = useTranslation();

    const handleOnValueChanged = (value: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("page");
        searchParams.set("sort_by", value);
        const newPathName = `${pathName}?${searchParams.toString()}`;
        // router.push(newPathName);
        window.location.href = newPathName;
    };

    return (
        <Select value={sort} onValueChange={handleOnValueChanged}>
            <SelectTrigger className={cn("bg-transparent border-input rounded-full", className)}>
                <SelectValue placeholder="Order By" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {sortDataSource.map((item) => (
                        <SelectItem key={item} value={item}>
                            {t(`product_list.sort_options.${item}` as any)}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

export default OrderSelection;
