import { defaults } from "lodash";
import * as z from "zod";

export class ContactUsModel {
    public static schema = z
        .object({
            name: z
                .string({
                    required_error: "contact_us.name_empty",
                })
                .min(1, {
                    message: "contact_us.name_invalid",
                })
                .max(128)
                .trim(),
            tel: z
                .string({
                    required_error: "contact_us.tel_empty",
                })
                .regex(
                    /^([02]*-([0-9]{3})*-([0-9]{4})+$|^([0-9]{3})*-([0-9]{3})*-([0-9]{4}))+$|^(([02]+[0-9]{7})+$|^([0-9]{10}))+$/,
                    {
                        message: "contact_us.tel_invalid",
                    },
                )
                .min(1, {
                    message: "contact_us.tel_empty",
                })
                .max(10)
                .trim(),
            email: z
                .string({
                    required_error: "contact_us.email_empty",
                })
                .min(1, {
                    message: "contact_us.email_empty",
                })
                .regex(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, {
                    message: "contact_us.email_invalid",
                })
                .trim(),
            message: z
                .string({
                    required_error: "contact_us.message_empty",
                })
                .min(1, {
                    message: "contact_us.message_empty",
                })
                .max(1024)
                .trim(),
        })
        .required({
            name: true,
            tel: true,
            email: true,
            message: true,
        });

    public static model = (data: Partial<ContactUsSchema>) => {
        return defaults<Partial<ContactUsSchema>, ContactUsSchema>(data || {}, {
            name: "",
            tel: "",
            email: "",
            message: "",
        });
    };
}

export type ContactUsSchema = z.infer<typeof ContactUsModel.schema>;
