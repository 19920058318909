import FilterSelectionGroupItem, { FilterSelectionGroupItemData } from "./filter-selection-group-item";
import { useTranslation } from "@/app/i18n/client";

type Props = {
    items: FilterSelectionGroupItemData[];
    filterType: string;
    selected: string[];
    onSelectedFilter?: (type: string, selected: string, checked: boolean) => void;
};

const FilterSelectionGroup = ({ filterType, onSelectedFilter, selected, items }: Props) => {
    const { t, loc } = useTranslation();
    const title = `product_list.filter_options.${filterType}`;
    return (
        <div className="flex flex-col gap-5">
            <h5>{t(title as any)}</h5>
            <div className="flex flex-col gap-3">
                {items.map((item) => (
                    <FilterSelectionGroupItem
                        key={item.id}
                        data={item}
                        checked={selected.indexOf(item.value) > -1}
                        onCheckedChange={(checked) => onSelectedFilter?.(filterType, item.value, checked)}
                    />
                ))}
            </div>
        </div>
    );
};

export default FilterSelectionGroup;
