"use client";

import { useState } from "react";
import { Plus, X } from "lucide-react";
import { Button } from "@/components/ui/button";

export const ExpandQuestion = ({ item, open }: { item: any; open: Boolean }) => {
    const [isOpen, setIsOpen] = useState(open);

    return (
        <div key={item.id} className="flex flex-col border-b py-6 gap-4">
            <div className="flex justify-between gap-10">
                <h3
                    key={item.attributes.id}
                    className="w-full font-normal text-xl text-wrap text-start whitespace-pre-line text-black cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)}>
                    {item.attributes.displayName}
                </h3>
                <Button variant="icon" className="bg-transparent border-c2 rounded-full border w-6 h-6" size="icon">
                    {isOpen ? (
                        <X onClick={() => setIsOpen(!isOpen)} size={14} strokeWidth={3} className="text-c2" />
                    ) : (
                        <Plus onClick={() => setIsOpen(!isOpen)} size={14} strokeWidth={3} className="text-c2" />
                    )}
                </Button>
            </div>
            {isOpen ? (
                <div
                    className="font-light text-base text-wrap text-start"
                    dangerouslySetInnerHTML={{ __html: item.attributes.description }}
                />
            ) : null}
        </div>
    );
};

export default ExpandQuestion;
