import { CallAPI } from "@/util/GetAPIPermission";
import { APIForgotPasswordResult, APISetNewPasswordResult, APISignEmailResult, APISignInResult } from "./api.type";
import { baseAPI } from "@/util/api.type";

export type SignInRequestParams = {
    username: string;
    phoneNumber?: string;
    password?: string;
    authFlow?: string;
    guestId?: string;
};

export type ForgotPasswordParams = {
    username: string;
};

export type SetNewPasswordParams = {
    username: string;
    password: string;
    confirmPassword: string;
};

export type GetCustomerParams = {
    username: string;
    nationalId: string;
};

export type SignUpRequestParams = {
    email: string;
    password: string;
};

export type SignInEmailRequestParams = {
    email: string;
    password: string;
};

export const CallAPI_SignIn = async (request: SignInRequestParams) => {
    const result = await CallAPI<APISignInResult>("/auth/customer/login-cognito", "POST", request);
    return result;
};

export const CallAPI_RequestSignInOTP = async (request: SignInRequestParams) => {
    const result = await CallAPI<APISignInResult>("/auth/customer/login-cognito-get-otp", "POST", request);
    return result;
};

export const CallAPI_ForgotPassword = async (request: ForgotPasswordParams) => {
    const result = await CallAPI<APIForgotPasswordResult>("/auth/customer/forgot-password", "POST", request);
    return result;
};

export const CallAPI_SetNewPassword = async (request: SetNewPasswordParams) => {
    const result = await CallAPI<APISetNewPasswordResult>("/auth/customer/set-new-password", "POST", request);
    return result;
};

export const CallAPI_CustomerInquiry = async (request: GetCustomerParams) => {
    const result = await CallAPI<any>("/auth/customer/get-customer-inquiry", "POST", request);
    return result;
};

export const CallAPI_SignUpEmail = async (request: SignUpRequestParams) => {
    const result = await CallAPI<APISignInResult>("/auth/customer/register", "POST", request);
    return result;
};

export const CallAPI_SignInEmail = async (request: SignInEmailRequestParams) => {
    console.log('CallAPI_SignInEmail');
    
    const result = await CallAPI<APISignEmailResult>("/auth/customer/user-login", "POST", request);
    return result;
};
