"use client";

import { getUser } from "@/actions";
import { setCookie } from "cookies-next";
import { useEffect } from "react";

const ShareCookie = ({ shareCid }: { shareCid?: string }) => {
    useEffect(() => {
        const verifyShareCid = async () => {
            const user = await getUser();
            const level = user?.level ?? "GUEST";
            if (level === "GUEST" && shareCid) {
                setCookie("share_cid", shareCid);
            }
        };
        if (shareCid) {
            verifyShareCid();
        }
    }, [shareCid]);

    return null;
};

export default ShareCookie;
