"use client";

import { MSProductWithAttribute, MSProductWithFullDetail } from "@/util/query/product-query";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import CarouselPageControl from "../controls/carousel-page-control";
import { useState, useEffect } from "react";
import { useParams } from "next/navigation";
import { useTranslation } from "@/app/i18n/client";
import CollectionListItem from "../collections/collection-list-item";
import { CUSTOMER_LEVEL } from "@prisma/client";

interface Props {
    products: MSProductWithFullDetail[];
    level: CUSTOMER_LEVEL;
}

const ProductRelatedList = ({ products, level }: Props) => {
    const { t } = useTranslation();
    const params = useParams();

    const collection = params.slug ? `/collections/${params.slug}` : "";

    const [api, setApi] = useState<CarouselApi>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [numShowInCarousel, setNumShowInCarousel] = useState<number>(4);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 1024) {
                setNumShowInCarousel(4);
            } else {
                setNumShowInCarousel(3);
            }
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (!api) {
            return;
        }

        api.on("select", () => {
            console.log(api.slidesNotInView());
            setSelectedIndex(api.selectedScrollSnap());
            console.log(api.slidesNotInView());
        });
    }, [api]);

    if (products.length === 0) {
        return null;
    }

    const handleOnNext = () => {
        if (!api) {
            return;
        }

        if (selectedIndex + numShowInCarousel >= products.length) {
            return;
        }
        api?.scrollNext();
    };

    const handleOnPrevious = () => {
        if (!api) {
            return;
        }

        if (selectedIndex - 1 < 0) {
            return;
        }
        api?.scrollPrev();
    };

    return (
        <div className="w-full">
            <div className="hidden md:flex flex-col items-center gap-4 w-full">
                <Carousel
                    setApi={setApi}
                    opts={{
                        align: "start",
                    }}
                    className="w-full">
                    <CarouselContent>
                        {products.map((product) => (
                            <CarouselItem key={product.id} className="basis-1/3 lg:basis-1/4">
                                <CollectionListItem
                                    product={product}
                                    href={`${collection}/products/${product.handle}`}
                                    t={t}
                                    level={level}
                                />
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </Carousel>
                <CarouselPageControl
                    className="self-end"
                    buttonClassName="border border-c4 rounded-full disabled:border-grayc3"
                    onNext={handleOnNext}
                    onPrevious={handleOnPrevious}
                    disabledNext={selectedIndex + numShowInCarousel >= products.length}
                    disabledPrevious={selectedIndex - 1 < 0}
                />
            </div>
            <div className="md:hidden grid grid-cols-2 gap-y-8 gap-x-4 w-full">
                {products.map((product) => (
                    <CollectionListItem
                        key={product.id}
                        product={product}
                        href={`${collection}/products/${product.handle}`}
                        t={t}
                        level={level}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProductRelatedList;
