"use client";

import { MSProductWithFullDetail } from "@/util/query/product-query";
import ProductPromotionImageGallery from "./product-promotion-gallery";
import ProductPromotionHeaderDetail from "./product-promotion-header-details";
import { toPriceString } from "@/util/util";
import { useState } from "react";

const ProductPromotionHeader = ({ product }: { product: MSProductWithFullDetail }) => {
    const defaultVariantIndex = 0;

    const [imageUrl, setImageUrl] = useState<string | null>(
        product.productVariant.length > 0
            ? product.productVariant[defaultVariantIndex].featuredImageUrl
            : product.featuredImageUrl,
    );
    const [weight, setWeight] = useState<number>(
        product.productVariant.length > 0 ? product.productVariant[defaultVariantIndex].weight : 0,
    );

    const handleOnSelectedVariantIndex = (index: number) => {
        console.log(product.productVariant[index]);
        const image = product.productVariant[index].featuredImageUrl;
        setImageUrl(image);
        const weight = product.productVariant[index].weight;
        setWeight(weight);
    };

    return (
        <div className="md:flex justify-start md:items-start p-2 gap-5 rounded-2xl my-2">
            <ProductPromotionImageGallery imageUrl={imageUrl} weightshow={toPriceString(weight ?? 0)} />
            <div className="mt-5 md:mt-0"></div>
            <ProductPromotionHeaderDetail product={product} onSelectedVariantIndex={handleOnSelectedVariantIndex} />
        </div>
    );
};

export default ProductPromotionHeader;
