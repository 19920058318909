"use client";

import { Checkbox } from "../ui/checkbox";

export type FilterSelectionGroupItemData = {
    id: string;
    title: string;
    value: string;
};

export type FilterSelectionGroupItemProps = {
    data: FilterSelectionGroupItemData;
    checked?: boolean;
    onCheckedChange?: (checked: boolean) => void;
};

const FilterSelectionGroupItem = ({ data, checked, onCheckedChange }: FilterSelectionGroupItemProps) => {
    return (
        <div className="flex items-start gap-2">
            <Checkbox id={data.id} className="w-5 h-5 mt-1" checked={checked} onCheckedChange={onCheckedChange} />
            <label htmlFor={data.id} className="text-lg font-light text-c4">
                {data.title}
            </label>
        </div>
    );
};

export default FilterSelectionGroupItem;
