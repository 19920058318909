"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ContactUsModel, ContactUsSchema } from "./contact-us-model";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useTranslation } from "@/app/i18n/client";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { sendContactForm } from "@/util/query/contact-query";
import { useState } from "react";
import { X } from "lucide-react";
import * as Dialog from "@radix-ui/react-dialog";

const ContactUsForm = () => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const form = useForm<ContactUsSchema>({
        defaultValues: ContactUsModel.model({}),
        resolver: zodResolver(ContactUsModel.schema),
    });

    const onSubmit = async (values: ContactUsSchema) => {
        const result = await sendContactForm(values);
        if (result) {
            form.reset();
            setIsOpen(true);
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5">
                <div className="flex-1">
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("contact_us.form.name.display")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        value={field.value || ""}
                                        type="text"
                                        maxLength={255}
                                        className={cn("form-input", formState.errors.name && "form-input-error")}
                                    />
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}></FormField>
                </div>

                <div className="flex-1 flex flex-col md:flex-row gap-5">
                    <div className="flex-1">
                        <FormField
                            control={form.control}
                            name="tel"
                            render={({ field, formState }) => (
                                <FormItem>
                                    <FormLabel className="form-input-label">
                                        {t("contact_us.form.tel.display")} <span className="text-price">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <div>
                                            <Input
                                                {...field}
                                                value={field.value || ""}
                                                type="tel"
                                                maxLength={10}
                                                className={cn("form-input", formState.errors.tel && "form-input-error")}
                                            />
                                            <FormDescription className="font-light text-xs text-grayc3">
                                                {t("contact_us.form.tel.desc")}
                                            </FormDescription>
                                        </div>
                                    </FormControl>
                                    <FormMessage translate="yes" />
                                </FormItem>
                            )}></FormField>
                    </div>

                    <div className="flex-1">
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field, formState }) => (
                                <FormItem>
                                    <FormLabel className="form-input-label">
                                        {t("contact_us.form.email.display")} <span className="text-price">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <div>
                                            <Input
                                                {...field}
                                                type="email"
                                                value={field.value || ""}
                                                className={cn(
                                                    "form-input",
                                                    formState.errors.email && "form-input-error",
                                                )}
                                            />
                                            <FormDescription className="font-light text-xs text-grayc3">
                                                {t("contact_us.form.email.desc")}
                                            </FormDescription>
                                        </div>
                                    </FormControl>
                                    <FormMessage translate="yes" />
                                </FormItem>
                            )}></FormField>
                    </div>
                </div>

                <div className="flex-1">
                    <FormField
                        control={form.control}
                        name="message"
                        render={({ field, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("contact_us.form.message.display")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <div>
                                        <textarea
                                            {...field}
                                            value={field.value || ""}
                                            maxLength={1024}
                                            rows={5}
                                            className={cn(
                                                "w-full border rounded-md px-3 py-2 resize-none outline-none",
                                                formState.errors.message && "form-input-error",
                                            )}
                                        />
                                    </div>
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}></FormField>
                </div>

                <div>
                    <Dialog.Root open={isOpen}>
                        <Dialog.Trigger asChild>
                            <Button variant="gradient1" type="submit" className="px-5">
                                {t("contact_us.form.submit_btn")}
                            </Button>
                        </Dialog.Trigger>
                        <Dialog.Portal>
                            <Dialog.Overlay
                                className="fixed bg-black bg-opacity-20 inset-0"
                                onClick={() => setIsOpen(false)}
                            />
                            <Dialog.Content className="fixed outline-none w-fit h-fit bg-transparent top-[20%] bottom-[20%] left-[5%] right-[5%] sm:top-[20%] sm:left-[10%] sm:right-[10%] lg:left-[15%] lg:right-[15%] m-auto">
                                <div className="flex flex-col justify-center bg-white h-full w-full rounded-lg p-16">
                                    <span className="font-medium text-c2 text-3xl text-center">
                                        {t("contact_us.thank_you_contacting")}
                                    </span>
                                    <span className="font-normal text-black text-base text-center">
                                        {t("contact_us.respond_soon")}
                                    </span>
                                </div>

                                <Dialog.Close asChild>
                                    <div className="absolute right-0 top-0 px-4 sm:px-0">
                                        <button className="self-end p-2">
                                            <X onClick={() => setIsOpen(false)} />
                                        </button>
                                    </div>
                                </Dialog.Close>
                            </Dialog.Content>
                        </Dialog.Portal>
                    </Dialog.Root>
                </div>
            </form>
        </Form>
    );
};

export default ContactUsForm;
