import { Star } from "lucide-react";
import React from "react";

const StarRating = ({ score }: { score: number }) => {
    return (
        <div className="flex">
            {[0, 1, 2, 3, 4].map((i) => (
                <Star
                    key={i}
                    className="w-6 h-6"
                    fillOpacity={1}
                    fill={score > i ? "#FFBC35" : "#FFBC3555"}
                    stroke="0"
                />
            ))}
        </div>
    );
};

export default StarRating;
