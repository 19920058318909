"use client";

import { MSProductVariantWithAttribute, MSProductWithFullDetail } from "@/util/query/product-query";
import { Button } from "../ui/button";
import RadioButtonGroup from "../controls/radio-button-group";
import StarRating from "../controls/start-rating";
import { Loader2, TimerIcon, Truck } from "lucide-react";
import FavoriteButton from "../controls/favorite-button";
import { useEffect, useState } from "react";
import ProductPriceGroup from "./product-price-group";
import { useRouter } from "next/navigation";
import QuantityControl from "../controls/quantity-control";
import { useTranslation } from "@/app/i18n/client";
import toast from "react-hot-toast";
import CartNotification from "../cart/cart-notification";
import { useSession } from "next-auth/react";
import { AddItemToCartRequestParams } from "@/util/services/cart/api";
import * as actions from "@/actions";
import { CUSTOMER_LEVEL } from "@prisma/client";
import { useCart } from "@/lib/providers/cart-provider";
import { useSystemConfig } from "@/lib/providers/system-config-provider";
import ProductShareButton from "./product-share-button";
import Link from "next/link";
import { getEasyFormUrl } from "@/util/util";
export interface CartItem {
    productName: string;
    quantity: number;
}
interface Props {
    product: MSProductWithFullDetail;
    promotionVariantProduct?: MSProductWithFullDetail;
    startVariantId?: string | null | undefined;
    onSelectedVariantIndex?: (index: number) => void;
}

const ProductHeaderDetail = ({ product, promotionVariantProduct, startVariantId, onSelectedVariantIndex }: Props) => {
    const { t, loc } = useTranslation();

    const variant = product.productVariant;

    const startIndex = startVariantId ? variant.findIndex((variant) => variant.id === startVariantId) : null;

    const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(startIndex ?? 0);
    const [selectedVariant, setSelectedVariant] = useState<MSProductVariantWithAttribute | null>(
        variant.length > 0 ? variant[0] : null,
    );

    const [requireQuantity, setRequireQuantity] = useState<number>(1);
    const [isAddingItem, setAddingItem] = useState<boolean>(false);
    const [level, setLevel] = useState<CUSTOMER_LEVEL>("GUEST");

    const [easyformUrl, setEasyFormUrl] = useState<string>(
        process.env.NEXT_PUBLIC_EASYFORM_URL || `https://easyapp.ulifespace.com`,
    );

    const session = useSession();
    const { addItem, getQuantityForItem } = useCart();
    const router = useRouter();
    const { canPurchase } = useSystemConfig();

    const isOutOfStock = selectedVariant ? selectedVariant.inventory - selectedVariant.inventory_used <= 0 : false;
    const isEnableSellButton = selectedVariant?.enableSellButton ?? true;
    const variantAttr = selectedVariant?.attributes?.find((x) => x.loc === loc);
    const quantityInCart = getQuantityForItem(product.handle);
    const stock = selectedVariant ? selectedVariant.inventory - selectedVariant.inventory_used : 0;
    const maxQuantity = stock - quantityInCart;
    const isAddAllStockInCart = quantityInCart >= stock && stock > 0;
    const isSoldOut = product.sell_state === "SOLD_OUT";
    const isComingSoon = product.sell_state === "COMING_SOON";
    const isReadyForSell = product.sell_state === "READY_FOR_SELL";

    const isSpecialPromotion =
        (promotionVariantProduct?.productVariant.findIndex((x) => x.variant_id === selectedVariant?.variant_id) ??
            -1) >= 0;
    const handleSelectedVariant = (index: number) => {
        setSelectedVariantIndex(index);
        onSelectedVariantIndex?.(index);
        setRequireQuantity(1);
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("variant", variant[index].id);
        window.history.replaceState(null, "", `?${searchParams.toString()}`);
    };

    const handleOnAddCart = async () => {
        if (!selectedVariant) {
            return;
        }

        const productToAdd = {
            idProduct: product.id,
            idVariant: selectedVariant.id,
            quantity: requireQuantity,
        };
        if (session.status === "authenticated") {
            await addItemToCart(productToAdd);
        } else if (session.status === "unauthenticated") {
            await actions.createGuest();
            await addItemToCart(productToAdd);
            setTimeout(() => window.location.reload(), 1000);
        }
    };

    const addItemToCart = async (productToAdd: AddItemToCartRequestParams) => {
        if (!selectedVariant) {
            return;
        }
        setAddingItem(true);

        try {
            await addItem(productToAdd);

            setAddingItem(false);
            toast.custom(
                (t) => (
                    <CartNotification
                        visible={t.visible}
                        onClose={() => toast.remove(t.id)}
                        product={product}
                        variant={selectedVariant}
                        type={
                            level === "BP_VVIP"
                                ? "vvip"
                                : level === "BP_VIP"
                                  ? "vip"
                                  : level === "NORMAL"
                                    ? "trial"
                                    : "normal"
                        }
                    />
                ),
                { position: "top-right", duration: 2000 },
            );
            router.refresh();
        } catch (error: any) {
            setAddingItem(false);
            toast.error(error.message, { position: "bottom-center" });
        }
    };

    useEffect(() => {
        if (variant.length === 0) {
            return;
        }
        setSelectedVariant(variant[selectedVariantIndex]);
    }, [selectedVariantIndex]);

    useEffect(() => {
        const getUserLevel = async () => {
            const user = await actions.getUser();
            setLevel(user?.level ?? "GUEST");
            setEasyFormUrl(getEasyFormUrl(user?.customerAccount as string));
        };
        if (session.status !== "loading") {
            if (session.data?.user) {
                getUserLevel();
            } else {
                setLevel("GUEST");
            }
        }
    }, [session.status]);

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5 text-c4">
                <h1 className="text-2xl font-normal">{product.attributes?.displayName ?? ""}</h1>
                <div
                    className="font-light text-c4 text-sm"
                    dangerouslySetInnerHTML={{
                        __html: product.attributes?.description ?? "",
                    }}
                />
            </div>
            <div className="flex justify-between items-center">
                {product.isShowRating && <StarRating score={product.averageRating ?? 0} />}
                <div className="flex gap-5">
                    <FavoriteButton productHandle={product.handle} />
                    <ProductShareButton />
                </div>
            </div>

            {isSpecialPromotion && variantAttr?.customPromoMessage && (
                <div className="flex items-center gap-1">
                    <TimerIcon />
                    <div className="flex items-center gap-2">
                        <span className="max-md:text-sm">{t("product.promotion_special")}</span>
                        <span className="max-md:text-sm text-protime">{variantAttr.customPromoMessage}</span>
                    </div>
                </div>
            )}

            {variant.length > 0 && (
                <RadioButtonGroup
                    startIndex={selectedVariantIndex}
                    onSelectedIndex={(index) => handleSelectedVariant(index)}
                    buttonTitles={variant.map((variant) => {
                        const attr = variant.attributes?.filter((atr) => atr.loc === loc);
                        const otherAttr = variant.attributes?.filter((attr) => attr.loc !== loc);
                        let title: string | null = "";
                        if (attr && attr.length > 0) {
                            title = attr[0].opttion1;
                        }
                        if (!title) {
                            if (otherAttr && otherAttr.length > 0) {
                                title = otherAttr[0].opttion1;
                            }
                        }
                        return title ?? "";
                    })}
                />
            )}

            {variant.length > 0 && (
                <div className="flex flex-wrap gap-y-4">
                    {level === "BP_VVIP" && (
                        <>
                            <ProductPriceGroup variant={selectedVariant} type="vvip" />
                            <ProductPriceGroup variant={selectedVariant} type="normal" lineThrough={true} />
                            <ProductPriceGroup variant={selectedVariant} type="cv_vvip" />
                            <ProductPriceGroup variant={selectedVariant} type="qv_vvip" hideSeperator={true} />
                        </>
                    )}
                    {level === "BP_VIP" && (
                        <>
                            <ProductPriceGroup variant={selectedVariant} type="vip" />
                            <ProductPriceGroup variant={selectedVariant} type="normal" lineThrough={true} />
                            <ProductPriceGroup variant={selectedVariant} type="cv_vip" />
                            <ProductPriceGroup variant={selectedVariant} type="qv_vip" hideSeperator={true} />
                        </>
                    )}
                    {(level === "BP" || level === "BF") && (
                        <>
                            <ProductPriceGroup variant={selectedVariant} type="trial" />
                            <ProductPriceGroup variant={selectedVariant} type="normal" lineThrough={true} />
                            <ProductPriceGroup variant={selectedVariant} type="cv" />
                            <ProductPriceGroup variant={selectedVariant} type="qv" hideSeperator={true} />
                        </>
                    )}
                    {(level === "GUEST" || level === "NORMAL") && (
                        <>
                            <ProductPriceGroup variant={selectedVariant} type="trial" isGuest={level === "GUEST"} />
                            <ProductPriceGroup variant={selectedVariant} type="normal" isGuest={level === "GUEST"} />
                        </>
                    )}
                </div>
            )}

            {session.status === "loading" && (
                <div>
                    <Loader2 className="animate-spin w-6 h-6" />
                </div>
            )}

            {canPurchase && selectedVariant && !isOutOfStock && !isAddAllStockInCart && (
                <QuantityControl
                    value={requireQuantity}
                    min={selectedVariant.quantity_rule_min}
                    max={
                        maxQuantity > selectedVariant.quantity_rule_max
                            ? selectedVariant.quantity_rule_max
                            : maxQuantity
                    }
                    step={selectedVariant.quantity_rule_increment_step}
                    onQuantityChanged={setRequireQuantity}
                />
            )}
            {isReadyForSell && isOutOfStock && (
                <div>
                    <p className="text-lg font-normal text-error">{t("product.out_of_stock")}</p>
                </div>
            )}
            {isReadyForSell && !isOutOfStock && isAddAllStockInCart && (
                <div>
                    <p className="text-lg font-normal text-error">{t("product.all_stock_incart")}</p>
                </div>
            )}

            <div className="flex justify-start items-center gap-4 mt-2">
                {/* {selectedVariant?.enableSubscription && (
                    <Link href={`${easyformUrl}`} target="_blank" className="w-full max-w-[260px]">
                        <Button variant="gradient4" className="h-14 w-full">
                            {t("product.btn_register")}
                        </Button>
                    </Link>
                )} */}
                {getSellButton()}
            </div>

            <div className="flex gap-2 text-grText-g3">
                <Truck />
                <h4>
                    {t("product.delivery_fee")}{" "}
                    <span className="text-c4 font-light">{t("product.delivery_fee_condition")}</span>
                </h4>
            </div>
        </div>
    );

    function getSellButton() {
        if (isSoldOut) {
            return (
                <Button disabled={true} variant="disabled" className="h-14 w-full max-w-[260px]">
                    {t("product.sold_out")}
                </Button>
            );
        } else if (isComingSoon) {
            return (
                <Button disabled={true} variant="disabled" className="h-14 w-full max-w-[260px]">
                    {t("product.coming_soon")}
                </Button>
            );
        } else if (canPurchase && isEnableSellButton) {
            return (
                <Button
                    disabled={isOutOfStock || isAddAllStockInCart || !selectedVariant}
                    variant="disabled"
                    className="h-14 w-full max-w-[260px]"
                    onClick={handleOnAddCart}
                    isLoading={isAddingItem}>
                    {isOutOfStock
                        ? t("product.out_of_stock")
                        : level === "GUEST" || level === "NORMAL"
                          ? t("product.btn_buy_trial")
                          : t("product.btn_buy_1")}
                </Button>
            );
        }
        return null;
    }
};

export default ProductHeaderDetail;
