"use client";

import { useTranslation } from "@/app/i18n/client";
import ProductImageFullscreen from "./product-image-full-screen";

interface Props {
    children?: React.ReactNode;
    imageUrl: string | null;
    weightshow: string;
}

const ProductPromotionImageGallery = ({ children, imageUrl, weightshow }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="flexflex-col justify-center p-0">
            <div className="relative rounded-xl bg-grayc4 2xl:min-w-[30rem] xl:min-w-[25rem] md:min-w-[20rem]">
                <ProductImageFullscreen imageUrl={imageUrl ?? ""} className="object-contain" />
            </div>
            <p className="mt-5 text-sm text-c4">{t("product.weight", { weight: weightshow })}</p>
            {children}
        </div>
    );
};

export default ProductPromotionImageGallery;
