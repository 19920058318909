"use client";

import "./calendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Tooltip } from "@nextui-org/react";
import { iconpath } from "@/util/staticresourcespath";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { DatesSetArg } from "@fullcalendar/core";
import allLocales from "@fullcalendar/core/locales-all";
import { cn } from "@/lib/utils";
import DialogEvent from "./dialog-event";

type Props = {
    loc: string;
    calendarComponent: any;
};

const CalendarComponent = (props: Props) => {
    const { loc, calendarComponent } = props;
    const [currentDate, setCurrentDate] = useState<DatesSetArg>();
    const calendarRef = useRef<FullCalendar>(null);
    const [screen, setScreen] = useState(0);

    useEffect(() => {
        setScreen(window.innerWidth)
    }, []);

    const goNext = () => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.next();
    };

    const goPrevious = () => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.prev();
    };

    const handleDates = (rangeInfo: DatesSetArg) => {
        setCurrentDate(rangeInfo);
    };

    const renderEventContent = (info: any) => {
        return (
            <DialogEvent
                loc={loc}
                event={{
                    id: info.event.extendedProps.id,
                    displayName: info.event.extendedProps.displayName,
                    desktopImageUrl: info.event.extendedProps.desktopImageUrl,
                    mobileImageUrl: info.event.extendedProps.mobileImageUrl,
                    imageBackgroundColor: info.event.extendedProps.imageBackgroundColor,
                    description: info.event.extendedProps.description,
                    location: info.event.extendedProps.location,
                    introList: info.event.extendedProps.introList,
                    startDateTime: info.event.extendedProps.startDateTime,
                    endDateTime: info.event.extendedProps.endDateTime,
                }}
            />
        );
    };

    return (
        <>
            <div className="flex flex-row items-center gap-4">
                <Tooltip title="Previous">
                    <div className="relative">
                        <Image
                            src={iconpath + "vector_right.svg"}
                            alt={"vector_right"}
                            width={11}
                            height={0}
                            style={{ width: "100%", height: "auto" }}
                            unoptimized
                            className="cursor-pointer rotate-180"
                            onClick={() => goPrevious()}
                        />
                    </div>
                </Tooltip>
                <span className="text-2xl font-bold whitespace-nowrap">{currentDate?.view.title}</span>
                <Tooltip title="Next">
                    <div className="relative">
                        <Image
                            src={iconpath + "vector_right.svg"}
                            alt={"vector_right"}
                            width={11}
                            height={0}
                            style={{ width: "100%", height: "auto" }}
                            unoptimized
                            className="cursor-pointer"
                            onClick={() => goNext()}
                        />
                    </div>
                </Tooltip>
            </div>

            <div className={cn("rounded-lg overflow-hidden")}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={false}
                    initialView="dayGridMonth"
                    contentHeight={screen < 640 ? "500px" : "850px"}
                    editable
                    selectable
                    selectMirror
                    dayMaxEvents
                    weekends
                    fixedWeekCount={false}
                    datesSet={handleDates}
                    locales={allLocales}
                    showNonCurrentDates={false}
                    locale={loc}
                    events={calendarComponent.events.map((value: any) => {
                        return {
                            id: value.id,
                            title: value.attributes.displayName,
                            start: value.startDateTime,
                            end: value.endDateTime,
                            display: "block",
                            displayEventTime: false,
                            displayEventEnd: false,
                            extendedProps: {
                                id: value.attributes.id,
                                displayName: value.attributes.displayName,
                                desktopImageUrl: value.attributes.desktopImageUrl,
                                mobileImageUrl: value.attributes.mobileImageUrl,
                                imageBackgroundColor: value.attributes.imageBackgroundColor,
                                description: value.attributes.description,
                                location: value.attributes.location,
                                introList: value.attributes.introList,
                                startDateTime: value.startDateTime,
                                endDateTime: value.endDateTime,
                            },
                        };
                    })}
                    eventContent={renderEventContent}
                    // eventClick={(info) => (
                    //     <DialogEvent
                    //         event={{
                    //             id: info.event.extendedProps.id,
                    //             displayName: info.event.extendedProps.displayName,
                    //             desktopImageUrl: info.event.extendedProps.desktopImageUrl,
                    //             mobileImageUrl: info.event.extendedProps.mobileImageUrl,
                    //             imageBackgroundColor: info.event.extendedProps.imageBackgroundColor,
                    //             description: info.event.extendedProps.description,
                    //             location: info.event.extendedProps.location,
                    //             introList: info.event.extendedProps.introList,
                    //         }}
                    //     />
                    // )}
                    moreLinkContent={<div className="bg-c2 rounded-full w-2 h-2 ml-4" />}
                    ref={calendarRef}
                />
            </div>
        </>
    );
};

export default CalendarComponent;
