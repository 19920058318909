import { useTranslation } from "@/app/i18n/client";
import { cn } from "@/lib/utils";
import { toPriceString } from "@/util/util";
import { Truck } from "lucide-react";
import { Progress } from "../ui/progress";
import { ProgressIndicator } from "@radix-ui/react-progress";

interface Props {
    freeShippingPrice: number;
    price: number;
}

const CartShipping = ({ freeShippingPrice, price }: Props) => {
    const { t } = useTranslation();

    const isFreeShipping = price >= freeShippingPrice;
    const addMore = freeShippingPrice - price;
    const progress = (100 / freeShippingPrice) * price;

    return (
        <div
            className={cn(
                "py-2 px-4 rounded-md flex gap-4 items-center",
                isFreeShipping ? "bg-[#e6f8e7]" : "bg-orange-100",
            )}>
            <Truck className={cn("w-10 h-10", isFreeShipping ? "text-discount" : "text-grText-g3")} />
            {price >= freeShippingPrice ? (
                <div>
                    <h6 className="font-medium">{t("cart.delivery_fee")} </h6>
                    <p className="text-c4 text-sm font-light">
                        {t("cart.delivery_fee_condition", { price: toPriceString(freeShippingPrice) })}
                    </p>
                </div>
            ) : (
                <div className="flex flex-col gap-1 w-full">
                    <h6 className="font-medium">{t("cart.delivery_progress", { price: toPriceString(addMore) })}</h6>
                    <Progress value={progress} indicatorColor="bg-grText-g3" className="h-2 w-full" />
                </div>
            )}
        </div>
    );
};

export default CartShipping;
