"use client";

import CartListItem from "./cart-list-item";
import { useTranslation } from "@/app/i18n/client";
import { Cart } from "@/util/services/cart/api.type";
import { CUSTOMER_LEVEL } from "@prisma/client";
import { CartDisplayType } from "./cart-main";
import { MapPin } from "lucide-react";
import AddressEditor from "../address/address-editor";
import { useRouter } from "next/navigation";
import { AddressValidatorSchema } from "@/lib/validators/address";
import { useCart } from "@/lib/providers/cart-provider";
import { createAddress, getUserWithAddresses } from "@/actions/user.address";
import { FormEvent, useEffect, useState } from "react";
import { IUserAddress } from "@/util/query/user-address-query";
import toast from "react-hot-toast";
import { Select } from "../ui/select";
import AddressSelection from "../forms/address/address-selection";
import { useProfile } from "@/lib/providers/profile-provider";
import { verifyStateIdForAddress } from "@/actions/address";

interface Props {
    level: CUSTOMER_LEVEL;
    display: CartDisplayType;
}

const CartList = ({ level, display }: Props) => {
    const { t, lang } = useTranslation();
    const router = useRouter();
    const { setAddressId, cart } = useCart();
    const { getProfile, assignUserAddress, getAddresses } = useProfile();
    const [defaultAddress, setDefaultAddress] = useState<IUserAddress>();
    const [defaultEmail, setDefaultEmail] = useState<string>("");
    const [addresses, setAddresses] = useState<IUserAddress[]>([]);
    const [updateDefaultAddress, setUpdateDefaultAddress] = useState<boolean>(true);

    const onSubmit = async (values: AddressValidatorSchema, e: any) => {
        e.preventDefault();

        try {
            const address_id = await assignUserAddress({
                cityId: values.district,
                addressName: "",
                receiver_name: values.first_name,
                receiver_surname: values.last_name,
                receiver_tel: values.mobile,
                note: "",
                address: values.address,
                is_default: values.is_default ?? false,
                email: values.email,
                zip_code: values.zip_code,
                stateId: values.stateId,
            });
            setAddressId(address_id);

            router.push("/payment");
        } catch (error: any) {
            toast.error(error.message, { position: "bottom-center" });
        }
    };

    const onSubmitSelectedAddress = async (selectedId: string, e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const address = addresses.find((x) => x.id === selectedId);
        if (address) {
            if (!address.address.stateId) {
                try {
                    await verifyStateIdForAddress(address.id);
                    setAddressId(selectedId);
                    router.push("/payment");
                } catch {
                    toast.error("ที่อยู่ของท่านไม่ถูกต้อง", { position: "bottom-center" });
                }
            } else if (!address.address.receiver_tel) {
                // do nothing must have telephone
            } else {
                setAddressId(selectedId);
                router.push("/payment");
            }
        }
    };

    useEffect(() => {
        const _getAddresses = async () => {
            const profile = await getProfile();
            const addresses = await getAddresses();
            if (profile) {
                setDefaultEmail(profile.email?.includes("@dummy.com") ? "" : profile.email ?? "");
                setAddresses(addresses);
                if (profile.level === "GUEST" || profile.level === "NORMAL") {
                    const defaultId = profile.defaultAddressId;
                    if (defaultId) {
                        const address = addresses.filter((a) => a.id === defaultId);
                        if (address.length > 0) {
                            setDefaultAddress(address[0]);
                        }
                    }
                } else {
                    const defaultId = profile.defaultAddressId;
                    if (defaultId) {
                        const address = addresses.find((i) => i.id === defaultId);
                        setDefaultAddress(address);
                    }
                }
            }
            setUpdateDefaultAddress(false);
        };
        _getAddresses();
    }, []);

    return (
        <div className="flex flex-col py-1 w-full">
            <h1 className="text-c2 font-medium">
                {display === "cart" ? t("cart.cart_title") : t("checkout.checkout_title")}
            </h1>
            <div className="flex flex-col">
                {cart?.saleItems.map((item) => (
                    <div className="border-b py-8" key={item.id}>
                        {/* <CartListItem item={item} level={level} needCalculateCart={display === 'cart' ? false : true} /> */}
                        <CartListItem item={item} level={level} needCalculateCart={true} />
                    </div>
                ))}
            </div>
            {display === "checkout" && (
                <div className="flex flex-col w-full py-8 gap-8 border-b">
                    <div className="flex items-center gap-2">
                        <MapPin className="w-5 h-5 text-c4" />
                        <h3 className="text-c4 font-medium">{t("checkout.checkout_address")}</h3>
                    </div>
                    {level === "GUEST" || level === "NORMAL" ? (
                        <div>
                            <AddressEditor
                                id="address-form"
                                onSubmit={onSubmit}
                                disableSetDefault={true}
                                defaultValues={{
                                    province: defaultAddress?.address.cityId ?? "", // Gie Fix Wait Ped Check
                                    district: defaultAddress?.address.cityId ?? "", // Gie Fix Wait Ped Check
                                    country:
                                        (lang === "th"
                                            ? defaultAddress?.address.countryTH ?? "ประเทศไทย"
                                            : defaultAddress?.address.countryEN ?? "Thailand") ?? "",
                                    stateId: defaultAddress?.address.stateId ?? "",
                                    address: defaultAddress?.address.address ?? "",
                                    first_name: defaultAddress?.address?.receiver_name ?? "",
                                    last_name: defaultAddress?.address?.receiver_surname ?? "",
                                    mobile: defaultAddress?.address?.receiver_tel ?? "",
                                    zip_code: defaultAddress?.address?.postcode ?? "",
                                    email: defaultEmail,
                                }}
                                requireEmail={true}
                                updateDefaultValue={updateDefaultAddress}
                            />
                        </div>
                    ) : (
                        <div>
                            <AddressSelection
                                id="address-form"
                                addresses={addresses}
                                defaultId={defaultAddress?.id ?? ""}
                                onSubmitNewsAddress={onSubmit}
                                onSubmitSelectedAddress={onSubmitSelectedAddress}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CartList;
