import Link from "next/link";
import Image from "next/image";
import { cn } from "@/lib/utils";

interface Props {
    className?: string;
    href: string;
    src: string;
    alt: string;
    width: number;
    height: number;
}
const ProductImage = ({ href, src, alt, width, height, className }: Props) => {
    return (
        <div className="bg-grayc4 rounded-lg flex justify-center items-center aspect-square">
            <Link href={href}>
                <Image
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    className={cn("p-5 object-contain", className)}
                    unoptimized
                />
            </Link>
        </div>
    );
};

export default ProductImage;
