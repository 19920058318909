"use client";

import * as Tooltip from "@radix-ui/react-tooltip";
import "../Tooltip/tooltips.css";
import { Info } from "lucide-react";
import React, { useState } from "react";

interface CustomTooltipProps {
    text: string;
}

export const CustomTooltip = (props: CustomTooltipProps) => {
    const { text } = props;
    const [open, setOpen] = useState(false);

    return (
        <Tooltip.Provider>
            <Tooltip.Root open={open} delayDuration={0}>
                <Tooltip.Trigger asChild>
                    <Info                        
                        onMouseOver={() => setOpen(true)}
                        onMouseOut={() => setOpen(false)}
                        onClick={() => setOpen(true)}
                        className="fill-c2 text-white cursor-pointer"
                        size={16}
                    />
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content className="TooltipContent max-w-56 text-sm leading-5 py-1 px-3" sideOffset={3}>
                        {text}
                        <Tooltip.Arrow className="TooltipArrow" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
