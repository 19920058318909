"use client";
import { useSession } from "next-auth/react";
import MaxWidthWrapper from "../maxwidthwrapper";
import CartList from "./cart-list";
import { useTranslation } from "@/app/i18n/client";
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import OrderDetail from "./order-detail";
import * as actions from "@/actions";
import { CUSTOMER_LEVEL } from "@prisma/client";
import _debounce from "lodash.debounce";
import Link from "next/link";
import { buttonVariants } from "../ui/button";
import { useCart } from "@/lib/providers/cart-provider";
import Image from "next/image";
import ProductRelatedInCart from "./product-related-cart";

export type CartDisplayType = "cart" | "checkout" | "payment";

interface Props {
    displayType?: CartDisplayType;
}

const CartMain = ({ displayType }: Props) => {
    const display = displayType ?? "cart";
    const { t } = useTranslation();

    const session = useSession();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [level, setLevel] = useState<CUSTOMER_LEVEL>("GUEST");
    const { cart, calculateCart } = useCart();

    useEffect(() => {
        const getUserCart = async () => {
            const user = await actions.getUser();
            // if (display !== 'cart') {                
                await calculateCart();
            // }            
            setLevel(user?.level ?? "GUEST");
            setLoading(false);
        };

        if (session.status !== "loading") {
            if (session.data?.user) {
                setLoading(true);
                getUserCart();
            } else {
                setLoading(false);
            }
        }
    }, [session.status]);

    return (
        <MaxWidthWrapper className="py-8 2xl:px-0">
            {isLoading ? (
                <div className={cn("w-full h-full justify-center items-center", isLoading ? "flex" : "hidden")}>
                    <Loader2 className="animate-spin w-8 h-8" />
                </div>
            ) : cart && cart.saleItems.length > 0 ? (
                <div className="flex flex-col">
                    <div
                        className={cn(
                            "flex flex-col justify-center items-stretch lg:items-start lg:flex-row w-full gap-8 lg:gap-16",
                            isLoading ? "hidden" : "flex",
                        )}>
                        <div className="relative lg:flex-[65%]">
                            <CartList level={level} display={display} />
                        </div>
                        <div className="relative lg:flex-[25%] lg:sticky lg:top-[160px]">
                            <OrderDetail display={display} level={level} />
                        </div>
                    </div>
                    {display === "cart" && <ProductRelatedInCart level={level} />}
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center gap-8 flex-1 px-4">
                    <div>
                        <Image src="/assets/shopping_bag.svg" alt="shopping_success" width={177} height={122} />
                    </div>
                    <div className="flex flex-col items-center gap-4">
                        <h1 className="font-normal text-c4 text-center">{t("cart.empty_cart")}</h1>
                    </div>
                    <Link className={cn(buttonVariants({ variant: "outline" }), "w-[280px] h-[60px]")} href={"/"}>
                        {t("cart.btn_continue_shopping")}
                    </Link>
                </div>
            )}
        </MaxWidthWrapper>
    );
};

export default CartMain;
