"use client";

import { useTranslation } from "@/app/i18n/client";
import AddressEditor from "@/components/address/address-editor";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { AddressValidatorSchema } from "@/lib/validators/address";
import { IUserAddress } from "@/util/query/user-address-query";
import { ChevronUp, Plus } from "lucide-react";
import Link from "next/link";
import React, { FormEvent, useEffect, useState } from "react";

interface Props {
    id: string;
    addresses: IUserAddress[];
    defaultId: string;
    onSubmitSelectedAddress?: (selectedId: any, e: FormEvent<HTMLFormElement>) => void;
    onSubmitNewsAddress?: (values: AddressValidatorSchema, e: any) => void;
}

const AddressSelection = ({ id, addresses, defaultId, onSubmitSelectedAddress, onSubmitNewsAddress }: Props) => {
    const { t, lang } = useTranslation();
    const [selected, setSelected] = useState<string>();
    const [isNewAddress, setNewAddress] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [showEditLink, setShowEditLink] = useState<boolean>(false);

    const handleOnSubmit = (selectedId: string | undefined, e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedId) {
            onSubmitSelectedAddress?.(selected, e);
        } else {
            if (addresses.length === 0) {
                setError(t("checkout.address_empty"));
            } else {
                setError(t("checkout.address_not_selected"));
            }
        }
    };

    const handleOnSelected = (selectedId: string) => {
        setError(undefined);
        setShowEditLink(false);
        setSelected(selectedId);
        verifySelectedAddress(selectedId);
    };

    const handleAddNewAddress = () => {
        setNewAddress(!isNewAddress);
    };

    const getAddressString = (address: IUserAddress) => {
        const addressInfo = address.address;
        return lang === "th"
            ? `${addressInfo.address} ${addressInfo.cityTH} ${addressInfo.stateTH} ${addressInfo.postcode}`
            : `${addressInfo.address}, ${addressInfo.cityEN}, ${addressInfo.stateEN}, ${addressInfo.postcode}`;
    };

    const verifySelectedAddress = (selectedId: string) => {
        const selected = addresses.find((x) => x.id === selectedId);
        if (selected) {
            if (!selected.address.receiver_tel) {
                setShowEditLink(true);
                setError(t("checkout.address_error"));
            }
        }
    };

    useEffect(() => {
        setSelected(defaultId);
        verifySelectedAddress(defaultId);
    }, [defaultId]);

    return (
        <div className="flex flex-col gap-2">
            <form id={isNewAddress ? "" : id} onSubmit={(e) => handleOnSubmit(selected, e)}>
                <div className="flex flex-col gap-2">
                    <p className="form-input-label">{t("checkout.select_address")}</p>
                    <Select name="selectedId" value={selected} onValueChange={handleOnSelected}>
                        <SelectTrigger className={cn("w-full form-input rounded-md border border-input")}>
                            <SelectValue placeholder={t("checkout.select_address")} className="text-muted-foreground" />
                        </SelectTrigger>
                        <SelectContent>
                            {addresses.map((item) => (
                                <SelectItem key={item.id} className="form-input focus:text-c2" value={item.id}>
                                    <p className="wrapword text-lg font-normal">{getAddressString(item)}</p>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    {error && (
                        <p className="form-input-label text-price">
                            {error}{" "}
                            {showEditLink && (
                                <Link className="underline text-c2" href={`/profile/addresses/edit/${selected}`}>
                                    {t("checkout.address_edit")}
                                </Link>
                            )}
                        </p>
                    )}
                    <div className="text-c2">
                        <Button
                            type="button"
                            variant="link"
                            className="text-c2 text-lg font-normal px-0 hover:no-underline"
                            onClick={handleAddNewAddress}>
                            <div className="flex gap-2 items-center">
                                {isNewAddress ? <ChevronUp size={18} /> : <Plus size={18} />}
                                <p className="text-lg font-normal">{t("checkout.add_new_address")}</p>
                            </div>
                        </Button>
                    </div>
                </div>
            </form>
            {isNewAddress && (
                <AddressEditor id={isNewAddress ? id : ""} onSubmit={onSubmitNewsAddress} requireEmail={false} />
            )}
        </div>
    );
};

export default AddressSelection;
