"use client";

import { cn } from "@/lib/utils";
import { iconpath } from "@/util/staticresourcespath";
import Image from "next/image";
import { useEffect, useState } from "react";

type Props = {
    vid: {
        id: string;
        videoId: string;
    };
    active: string;
    isMove: number;
};

let player: any;

const YoutubeIframe = ({ vid, active, isMove }: Props) => {
    const [hideIframe, setHideIframe] = useState<boolean>(true);

    useEffect(() => {
        if (!window.YT) {
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            tag.id = `youtube-iframe-api-${vid.videoId}`;

            const firstScriptTag = document.getElementsByTagName("script")[0];
            firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
        }

        return () => {
            document.getElementById(`youtube-iframe-api-${vid.videoId}`)?.remove();
        };
    }, [vid.videoId]);

    useEffect(() => {
        if (active !== vid.id) {
            player = undefined;
            setHideIframe(true);
            document.getElementById(`yt-${vid.id}`)?.remove();
            const div = document.createElement("div");
            div.id = `yt-${vid.id}`;
            document.getElementById(`yt-wrapper-${vid.id}`)?.append(div);
        }
    }, [active, vid.id]);

    useEffect(() => {
        if (isMove === 0) {
            return;
        }

        if (active === vid.id) {
            if (player) {
                setHideIframe(true);
                player.stopVideo();
            }
        }
    }, [active, isMove, vid.id]);

    const onPlay = () => {
        if (hideIframe && !player) {
            player = new window.YT.Player(`yt-${vid.id}`, {
                videoId: vid.videoId,
                events: {
                    onReady: (event: any) => {
                        event.target.playVideo();
                    },
                    onStateChange: (event: any) => {
                        // -1 (unstarted)
                        // 0 (ended)
                        // 1 (playing)
                        // 2 (paused)
                        // 3 (buffering)
                        // 5 (video cued).
                        if (event.data === 0) {
                            setHideIframe(true);
                            document.getElementById(`yt-${vid.id}`)?.remove();
                            const div = document.createElement("div");
                            div.id = `yt-${vid.id}`;
                            document.getElementById(`yt-wrapper-${vid.id}`)?.append(div);
                        } else if (event.data === 1) {
                            setHideIframe(false);
                        } else if (event.data === 2) {
                            setTimeout(() => {
                                if (player?.getPlayerState() === 2) {
                                    setHideIframe(true);
                                }
                            }, 500);
                        } else if (event.data === 5) {
                            setHideIframe(true);
                        }
                    },
                },
            });
        } else {
            player.playVideo();
        }
    };

    return (
        <>
            <div id={"yt-wrapper-" + vid.id} className="w-full h-full">
                <div id={"yt-" + vid.id}></div>
            </div>
            <div
                className={cn(
                    "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-3 cursor-pointer w-full h-full",
                    !hideIframe ? "hidden" : "",
                )}>
                <Image
                    onClick={() => onPlay()}
                    src={"https://img.youtube.com/vi/" + vid.videoId + "/maxresdefault.jpg"}
                    alt={vid.videoId}
                    fill
                    style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                    }}
                    unoptimized
                    className="shadow-lg"
                />
            </div>
            <div
                className={cn(
                    "w-[50px] h-[30px] sm:w-[70px] sm:h-[50px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-10",
                    !hideIframe ? "hidden" : "",
                )}>
                <Image
                    onClick={() => onPlay()}
                    src={iconpath + "youtube_play.svg"}
                    alt={vid.videoId}
                    fill
                    unoptimized
                />
            </div>
        </>
    );
};

export default YoutubeIframe;
