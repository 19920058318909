"use client";

import { toPriceString } from "@/util/util";
import ProductHeaderDetail from "./product-header-detail";
import ProductImageGallery from "./product-image-gallery";
import { MSProductWithFullDetail } from "@/util/query/product-query";
import { useTranslation } from "@/app/i18n/client";
import { useSearchParams } from "next/navigation";
import { useState } from "react";

const ProductHeaderInfo = ({
    allVariantProduct,
    promotionVariantProduct,
}: {
    allVariantProduct: MSProductWithFullDetail;
    promotionVariantProduct?: MSProductWithFullDetail;
}) => {
    const disableImage = allVariantProduct.productVariant
        .filter((v) => !v.enableInFrontEnd && v.featuredImageUrl !== allVariantProduct.featuredImageUrl)
        .map((v) => v.featuredImageUrl);
    const product: MSProductWithFullDetail = {
        ...allVariantProduct,
        productVariant: allVariantProduct.productVariant.filter((v) => v.enableInFrontEnd),
        imagesUrl: allVariantProduct.imagesUrl.filter((url) => !disableImage.includes(url)),
    };

    const { t } = useTranslation();
    const searchParams = useSearchParams();
    const startVariant = searchParams.get("variant");
    const startIndex = startVariant ? product.productVariant.findIndex((variant) => variant.id === startVariant) : 0;
    const [startImageUrl, setStartImageUrl] = useState<string | null>(
        product.productVariant.length > 0
            ? product.productVariant[startIndex].featuredImageUrl
            : product.featuredImageUrl,
    );
    const [weight, setWeight] = useState<number>(
        product.productVariant.length > 0 ? product.productVariant[startIndex].weight : 0,
    );

    const handleOnSelectedVariant = (index: number) => {
        const image = product.productVariant[index].featuredImageUrl;
        setStartImageUrl(image);
        const weight = product.productVariant[index].weight;
        setWeight(weight);
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <ProductImageGallery imagesUrl={product.imagesUrl} currentImageUrl={startImageUrl}>
                <h6 className="font-light text-c4 mt-5 md:mt-8 mx-2 md:mx-0">
                    {t("product.weight", { weight: toPriceString(weight ?? 0) })}
                </h6>
            </ProductImageGallery>
            <div className="w-full px-4 md:px-0">
                <ProductHeaderDetail
                    product={product}
                    promotionVariantProduct={promotionVariantProduct}
                    startVariantId={startVariant}
                    onSelectedVariantIndex={handleOnSelectedVariant}
                />
            </div>
        </div>
    );
};

export default ProductHeaderInfo;
