import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { Minus, Plus } from "lucide-react";

interface Props {
    max?: number;
    step?: number;
    min?: number;
    value?: number;
    onQuantityChanged?: (quantity: number) => void;
    onIncrease?: () => void;
    onDecrease?: () => void;
}

const QuantityControl = ({ max, step, min, value, onQuantityChanged, onIncrease, onDecrease }: Props) => {
    const { t } = useTranslation();

    const [minValue, setMinValue] = useState<number>(min && min > 0 ? min : 1);
    const [stepValue, setStepValue] = useState<number>(step && step > 0 ? step : 1);
    const [maxValue, setMaxValue] = useState<number>(max && max > 0 ? max : 10000000);

    // const [quantity, setQuantity] = useState<number>(value ?? min ?? 1);

    const handleIncrease = () => {
        const newValue = (value ?? minValue) + stepValue;
        if (newValue <= maxValue) {
            onIncrease?.();
            // setQuantity(newValue);
            onQuantityChanged?.(newValue);
        }
    };

    const handleDecrease = () => {
        const newValue = (value ?? minValue) - stepValue;
        if (newValue >= minValue) {
            onDecrease?.();
            // setQuantity(newValue);
            onQuantityChanged?.(newValue);
        }
    };

    return (
        <div className="flex gap-4 text-c4 items-center select-none">
            <h5>{t("quantity")}</h5>
            <Button
                variant="icon"
                className="rounded-sm w-6 h-6"
                size="icon"
                disabled={(value ?? minValue) - stepValue < minValue}
                onClick={handleDecrease}>
                <Minus size={14} strokeWidth={3} />
            </Button>
            <h3 className="px-4 font-normal">{value ?? minValue}</h3>
            <Button
                variant="icon"
                className="rounded-sm w-6 h-6"
                size="icon"
                disabled={(value ?? minValue) + stepValue > maxValue}
                onClick={handleIncrease}>
                <Plus size={14} strokeWidth={3} />
            </Button>
        </div>
    );
};

export default QuantityControl;
