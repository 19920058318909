import { LocaleAttribute } from "@/app/i18n/settings";
import { db } from "@/db";
import { Category, CategoryAttb, Collection, CollectionAttb } from "@prisma/client";

const cacheCollection: { [key: string]: CollectionWithAttribute } = {};
const cacheCollectionExpire: { [key: string]: Date } = {};

const cacheAllCatgories: { [key: string]: CategoryWithAttribute[] } = {};
const cacheAllCatgoriesExpire: { [key: string]: Date } = {};
const expireInMin = 1;

export type CategoryWithAttribute = {
    attributes: CategoryAttb;
} & Category;

export type CollectionWithAttribute = {
    attributes: CollectionAttb;
} & Collection;

export const getCollectionByName = async (name: string, loc: LocaleAttribute) => {
    const key = `${name}_${loc}`;
    const curTime = new Date();
    if (cacheCollection[key]) {
        if (curTime < cacheCollectionExpire[key]) {
            return cacheCollection[key];
        }
    }

    const collection = await db.collection
        .findUnique({
            where: {
                handle: name,
            },
            include: {
                attributes: true,
            },
        })
        .then((result) => {
            if (result) {
                let catAttribute = result?.attributes.filter((attr) => attr.loc === loc);
                if (!catAttribute) {
                    catAttribute = [];
                }
                return {
                    ...result,
                    attributes: catAttribute?.length > 0 ? catAttribute[0] : result.attributes[0],
                };
            }
            return result;
        });

    if (collection) {
        cacheCollection[key] = collection;
        cacheCollectionExpire[key] = new Date(curTime.getTime() + expireInMin * 60000);
    }
    return collection;
};

export const getCollectionByIds = async (ids: string[], loc: LocaleAttribute) => {
    if (!ids || ids.length === 0) {
        return [];
    }

    const collections = await db.collection
        .findMany({
            where: {
                handle: {
                    in: ids,
                },
            },
            include: {
                attributes: true,
            },
        })
        .then((result) => {
            return [
                ...result.map((x) => {
                    const catAttribute = x.attributes.filter((attr) => attr.loc === loc);
                    return {
                        ...x,
                        attributes: catAttribute.length > 0 ? catAttribute[0] : x.attributes[0],
                    };
                }),
            ];
        });

    return collections ?? [];
};

export const getAllCategories = async (loc: LocaleAttribute) => {
    const key = `${loc}`;
    const curTime = new Date();
    if (cacheAllCatgories[key]) {
        if (curTime < cacheAllCatgoriesExpire[key]) {
            return cacheAllCatgories[key];
        }
    }
    const categories = await db.category
        .findMany({
            where: {
                visibility: true,
            },
            include: {
                attributes: true,
            },
        })
        .then((result) => {
            return [
                ...result.map((x) => {
                    const catAttribute = x.attributes.filter((attr) => attr.loc === loc);
                    return {
                        ...x,
                        attributes: catAttribute.length > 0 ? catAttribute[0] : x.attributes[0],
                    };
                }),
            ];
        });

    if (categories) {
        cacheAllCatgories[key] = categories;
        cacheAllCatgoriesExpire[key] = new Date(curTime.getTime() + expireInMin * 60000);
    }
    return categories;
};
