"use client";

import React, { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Heart } from "lucide-react";
import { cn } from "@/lib/utils";
import { useProfile } from "@/lib/providers/profile-provider";
import { getUser } from "@/actions";

interface Props {
    productHandle: string;
    className?: string;
    favClassName?: string;
    unFavClassName?: string;
    favIconColor?: string;
    refresh?: boolean;
    hearthsize?: number;
}

const FavoriteButton = ({
    productHandle,
    className,
    favClassName,
    unFavClassName,
    favIconColor,
    refresh,
    hearthsize = 20,
}: Props) => {
    const { favoriteIds, addFavorite, removeFavorite, isFavorite, statusLoading } = useProfile();
    const [loading, setLoading] = useState<boolean>(false);
    const [favorite, setFavorite] = useState<boolean>(false);
    const [hide, setHide] = useState<boolean>(true);

    useEffect(() => {
        const verifyUser = async () => {
            const user = await getUser();
            if (user && user.level !== "GUEST") {
                setHide(false);
            } else {
                setHide(true);
            }
        };
        verifyUser();
    }, []);

    useEffect(() => {
        const isFav = isFavorite(productHandle);
        setFavorite(isFav);
    }, [favoriteIds]);

    const handleOnClick = async () => {
        setLoading(true);
        if (!favorite) {
            await addFavorite(productHandle);
        } else {
            await removeFavorite(productHandle);

            if (refresh) {
                window.location.reload();
            }
        }
        setLoading(false);
    };

    return (
        <Button            
            isLoading={loading || statusLoading}
            className={cn(className, favorite ? "bg-c2" : "", favorite ? favClassName : unFavClassName, hide ? "hidden" : "")}
            variant={"icon"}
            size="icon"
            onClick={handleOnClick}>
            <Heart className={cn(favorite ? favIconColor ?? "text-white" : "")} size={hearthsize} />
        </Button>
    );
};

export default FavoriteButton;
