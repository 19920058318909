import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface Props {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    title: string;
    description: string;
    yesTitle?: string;
    noTitle?: string;
    onYes?: () => void;
    onNo?: () => void;
}

const AlertYesNoDialog = ({ title, description, yesTitle, noTitle, open, onOpenChange, onYes, onNo }: Props) => {
    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>{description}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogAction onClick={onYes}>{yesTitle || "ตกลง"}</AlertDialogAction>
                    <AlertDialogCancel onClick={onNo}>{noTitle || "ยกเลิก"}</AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default AlertYesNoDialog;
