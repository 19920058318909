"use client";

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui/collapsible";
import { Button } from "../ui/button";
import { Plus, X } from "lucide-react";
import { useState } from "react";

interface Props {
    title: string;
    description: string;
}

const ProductCollapsibleDetail = ({ title, description }: Props) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <Collapsible className="w-full" open={isOpen} onOpenChange={setIsOpen}>
            <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                    <h3 className="font-medium text-c2">{title}</h3>
                    <CollapsibleTrigger asChild>
                        <Button
                            variant="icon"
                            className="bg-transparent border-c2 rounded-full border w-6 h-6"
                            size="icon">
                            {isOpen ? (
                                <X size={14} strokeWidth={3} className="text-c2" />
                            ) : (
                                <Plus size={14} strokeWidth={3} className="text-c2" />
                            )}
                        </Button>
                    </CollapsibleTrigger>
                </div>
                <CollapsibleContent className="text-sm font-light">
                    <div
                        className="w-full"
                        dangerouslySetInnerHTML={{
                            __html: description ?? "",
                        }}
                    />
                </CollapsibleContent>
            </div>
        </Collapsible>
    );
};

export default ProductCollapsibleDetail;
