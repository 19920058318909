import { LocaleAttribute } from "@/app/i18n/settings";
import { addYears } from "date-fns";
import { format } from "date-fns-tz";
import { th, enUS } from "date-fns/locale";

export const DateToFormatWithTimeZone = (
    date: Date,
    loc: LocaleAttribute,
    dateFormat = "dd MMMM yyyy HH:mm:ss",
    timeZone = "Asia/Bangok",
) => {
    try {
        if (!date) {
            return null;
        }

        let locale = enUS;

        let tmpDate = date;
        if (loc === "TH") {
            locale = th;
            tmpDate = addYears(date, 543);
        }

        return format(tmpDate, dateFormat, { timeZone: timeZone, locale: locale });
    } catch {
        return null;
    }
};
