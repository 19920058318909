"use client";

import MaxWidthWrapper from "../maxwidthwrapper";
import "./business.css";
// Import Swiper React components
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import YoutubeIframe from "./youtube-iframe/youtube-iframe";
import { useEffect, useState } from "react";

// const itmContentVideoID: BusinessContent[] = [
//     {
//         id: "videoId1",
//         videoId: "M7--muwVUtk",
//     },
//     {
//         id: "videoId2",
//         videoId: "ijkxcrfr5ZY",
//     },
//     {
//         id: "videoId3",
//         videoId: "KVAm1vm3RJU",
//     },
//     {
//         id: "videoId4",
//         videoId: "M7--muwVUtk",
//     },
//     {
//         id: "videoId5",
//         videoId: "ijkxcrfr5ZY",
//     },
//     {
//         id: "videoId6",
//         videoId: "KVAm1vm3RJU",
//     },
// ];
export interface BusinessContent {
    id: string;
    videoId: string;
}

type Props = {
    list: string[];
};

export const BusinessVideoComponent = ({ list }: Props) => {
    const [currentActive, setCurrentActive] = useState<string>("");
    const [listVideoID, setListVideoID] = useState<BusinessContent[]>([]);
    const [isMove, setIsMove] = useState<number>(0);

    useEffect(() => {
        if (list.length > 0) {
            const newListLoop: BusinessContent[] = [];
            const newList = list.map((value, index) => {
                newListLoop.push({
                    id: `videoId${index}duplicated`,
                    videoId: value,
                });

                if (list.length === 1) {
                    newListLoop.push({
                        id: `videoId${index}duplicated2`,
                        videoId: value,
                    });
                }

                return {
                    id: `videoId${index}`,
                    videoId: value,
                };
            });
            const allList = [...newList, ...newListLoop];
            setListVideoID(allList);
            setCurrentActive(allList[0].id);
        }
    }, [list]);

    const onSlideChange = (swiper: SwiperClass) => {
        if (listVideoID.length > 0) {
            const activeIndex = swiper.realIndex;
            setCurrentActive(listVideoID[activeIndex].id);
        }
    };

    return (
        <>
            {list.length > 0 && (
                <MaxWidthWrapper className="py-8" disabledPadding={true} sectionWidth="FLUID" contentWidth="FLUID">
                    <Swiper
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        slidesPerView={1.6}
                        spaceBetween={20}
                        onSlideChange={onSlideChange}
                        onSliderFirstMove={() => setIsMove(Math.random())}
                        breakpoints={{
                            0: {
                                spaceBetween: 20,
                                slidesPerView: 1.2,
                            },
                            640: {
                                spaceBetween: 20,
                                slidesPerView: 2,
                            },
                            768: {
                                spaceBetween: 25,
                                slidesPerView: 1.8,
                            },
                            1024: {
                                spaceBetween: 30,
                                slidesPerView: 1.8,
                            },
                            1280: {
                                spaceBetween: 30,
                                slidesPerView: 2.07,
                            },
                            1536: {
                                spaceBetween: 30,
                                slidesPerView: 2.07,
                            },
                        }}
                        effect="slide"
                        className="swiper-container">
                        {listVideoID.map((item) => {
                            return (
                                <SwiperSlide key={item.id} className="select-none">
                                    <YoutubeIframe
                                        vid={{ id: item.id, videoId: item.videoId }}
                                        active={currentActive}
                                        isMove={isMove}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </MaxWidthWrapper>
            )}
        </>
    );
};

export default BusinessVideoComponent;
