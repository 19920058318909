"use client";

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Image from "next/image";
import { iconpath, imagepath } from "@/util/staticresourcespath";
import { useTranslation } from "@/app/i18n/client";
import { cn } from "@/lib/utils";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet";

type Props = {
    id: string;
    title: string;
    children: React.ReactNode;
    classNameContent?: string;
};

const DialogDetails = (props: Props) => {
    const { children, id, title, classNameContent } = props;

    return (
        <Sheet key={id}>
            <SheetTrigger asChild>
                <div className="flex flex-row gap-2 items-center cursor-pointer">
                    <h4 className="w-fit font-normal text-base text-wrap whitespace-pre-line text-c2 hover:underline">
                        {title}
                    </h4>

                    <div className="relative w-fit">
                        <Image
                            src={iconpath + "arrow_right.svg"}
                            alt={"arrow_right"}
                            width={0}
                            height={0}
                            style={{ width: "100%", height: "auto" }}
                            unoptimized
                        />
                    </div>
                </div>
            </SheetTrigger>

            <SheetContent className={cn("overflow-scroll no-scrollbar", classNameContent)}>
                {children}
                <SheetFooter>
                    <SheetClose asChild></SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>

        // <Dialog.Root key={id}>
        //     <Dialog.Trigger asChild>
        //         <div className="flex flex-row gap-2 items-center cursor-pointer">
        //             <span className="w-fit font-normal text-base text-wrap whitespace-pre-line text-c2 hover:underline">
        //                 {title}
        //             </span>

        //             <div className="relative w-fit">
        //                 <Image
        //                     src={iconpath + "arrow_right.svg"}
        //                     alt={"arrow_right"}
        //                     width={0}
        //                     height={0}
        //                     style={{ width: "100%", height: "auto" }}
        //                     unoptimized
        //                 />
        //             </div>
        //         </div>
        //     </Dialog.Trigger>
        //     <Dialog.Portal>
        //         <Dialog.Overlay className="fixed bg-black bg-opacity-20 inset-0" />
        //         <Dialog.Content className={cn("fixed outline-none bg-white m-auto top-[10%] bottom-[5%] left-[5%] right-[5%] sm:top-[20%] sm:right-[10%] sm:left-[10%] lg:right-[20%] lg:left-[20%]", classNameContent)}>
        //             {children}
        //             <Dialog.Close asChild>
        //                 <div className="inline-flex absolute top-0 right-0 w-17 h-17 p-4 z-10">
        //                     <Image
        //                         src={iconpath + "Union.png"}
        //                         alt={"Union"}
        //                         width={0}
        //                         height={0}
        //                         style={{ width: "100%", height: "100%", objectFit: "cover" }}
        //                         className="cursor-pointer"
        //                         unoptimized
        //                     />
        //                 </div>
        //             </Dialog.Close>
        //         </Dialog.Content>
        //     </Dialog.Portal>
        // </Dialog.Root>
    );
};

export default DialogDetails;
