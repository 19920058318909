"use client";

import { useTranslation } from "@/app/i18n/client";
import { MapPin, Receipt, ShoppingBag } from "lucide-react";
import { Input } from "../ui/input";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Label } from "../ui/label";
import { FormEvent, useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { PaymentMethod, useCart } from "@/lib/providers/cart-provider";
import { useRouter } from "next/navigation";
import Image from "next/image";
import toast from "react-hot-toast";
import { getAddress, getBillAddress } from "@/actions/user.address";
import { getUser } from "@/actions";
import { CUSTOMER_LEVEL } from "@prisma/client";
import { getCartById } from "@/actions/cart";
import { decrypt, encrypt } from "@/util/crypt";
import { verifyThaiId } from "@/util/util";
import AlertYesNoDialog from "../alert/alert-yesno-dialog";
import AlertOkDialog from "../alert/alert-ok-dialog";

const PaymentInfo = () => {
    const { t, lang } = useTranslation();
    const { getCart, cart, addressId, assignOrderAddressAndCheckout, checkIdCardWithAX } = useCart();
    const router = useRouter();
    const [receiptAddress, setReceiptAddress] = useState<string>("");
    const [idCard, setIdCard] = useState<string>("");
    const [idCardError, setIdCardError] = useState<string | null>();
    const [tax, setTax] = useState<"yes" | "no">("no");
    const [bag, setBag] = useState<"yes" | "no">("no");
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("credit");
    const [level, setLevel] = useState<CUSTOMER_LEVEL>("GUEST");
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openOkDialog, setOpenOkDialog] = useState<boolean>(false);
    const [canEditBillingAddress, setCanEditBillingAddress] = useState<boolean>(false);
    const [displayReceiptAddress, setDisplayReceiptAddress] = useState<string>();

    useEffect(() => {
        const getBillingAddress = async (addressId: string) => {
            const user = await getUser();
            setLevel(user?.level ?? "GUEST");

            if (user) {
                const address = await getAddress(addressId);
                if (address) {
                    const fullAddress = `${address?.address.address ?? ""} ${
                        lang === "th" ? address?.address.cityTH : address?.address.cityEN ?? ""
                    } ${lang === "th" ? address?.address.stateTH : address?.address.stateEN ?? ""} ${
                        address?.address.postcode ?? ""
                    }`;
                    setReceiptAddress(fullAddress);
                } else {
                    setReceiptAddress("");
                    setDisplayReceiptAddress("");
                }
            }
        };

        if (!addressId) {
            router.replace("/checkout");
            return;
        } else {
            getBillingAddress(addressId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressId]);

    const handleOnTaxChanged = (value: string) => {
        const val = (value as "yes" | "no") ?? "no";
        setTax(val);

        if (val === "yes") {
            setCanEditBillingAddress(true);
            setDisplayReceiptAddress(receiptAddress);
        } else {
            setCanEditBillingAddress(false);
            setDisplayReceiptAddress("");
        }
    };

    const handleOnBagChanged = (value: string) => {
        setBag((value as "yes" | "no") ?? "no");
    };

    const handleOnPaymentMethod = (value: string) => {
        setPaymentMethod(value as PaymentMethod);
    };

    const handleOnIdCardChanged = (value: string) => {
        setIdCard(value);
        setIdCardError(null);
    };

    const handleOnContinueForceLogin = async () => {
        const user = await getUser();
        if (user?.id) {
            const encodedId = Buffer.from(user.id, "utf-8").toString("base64");
            router.push(`/signin?id=${encodedId}`);
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        //const idCard = (e.currentTarget.elements.namedItem("idCard") as HTMLInputElement)?.value;
        const paymentMethod = (e.currentTarget.elements.namedItem("payment") as HTMLInputElement)
            .value as PaymentMethod;
        const tax = (e.currentTarget.elements.namedItem("tax") as HTMLInputElement).value === "yes";
        const receiptAddress = tax
            ? (e.currentTarget.elements.namedItem("receiptAddress") as HTMLInputElement).value
            : "";
        /*
        if (idCard && idCard.length > 0) {
            if (!verifyThaiId(idCard)) {
                setIdCardError(t("payment.payment_idCard_invalid"));
                return;
            }
            // let encryptIdCard = await encrypt(idCard);
            // if (!encryptIdCard) {
            //     setIdCardError(t("payment.payment_idCard_invalid"));
            //     return;
            // }

            try {
                const result = await checkIdCardWithAX(idCard);                
                if (result === "FORCE_LOGIN") {
                    setOpenDialog(true);
                    return;
                } else if (result === "CONTACT_STAFF") {
                    setOpenOkDialog(true);
                    return;
                } else if (result === "CONTINUE") {
                    // do nothing continue;
                }
            } catch (error: any) {
                // toast.error(error.message ?? t("error.general"), { position: "bottom-center" });
                setOpenOkDialog(true);
                return;
            }
        }
        */

        if (cart && addressId) {
            try {
                const result = await assignOrderAddressAndCheckout(addressId, paymentMethod, receiptAddress, tax);
                console.log("payment result = ");
                console.log(JSON.stringify(result));

                if (result && result.success) {
                    if (paymentMethod === "credit") {
                        router.replace(result.result.webPaymentUrl);
                    } else {
                        const saleOrder = await getCartById(cart.id);
                        router.replace(`/payment-result?order_id=${saleOrder?.docNo || ""}`);
                    }
                    return;
                } else {
                    toast.error(result?.error_friendly_message[lang] ?? t("error.general"), {
                        position: "bottom-center",
                    });
                }
            } catch (error: any) {
                const message = error.message;
                if (message === "PRODUCT_UNAVAILABLE") {
                    toast.error(t("payment.product_unavailable"), { position: "bottom-center", duration: 5000 });
                    getCart();
                    router.replace("/cart");
                } else {
                    toast.error(error.message ?? t("error.general"), { position: "bottom-center", duration: 5000 });
                }
            }
        }
    };

    return (
        <div className="flex flex-col py-1 w-full">
            <h1 className="text-c2 font-medium">{t("payment.payment_title")}</h1>
            <div className="flex flex-col w-full">
                <form id="payment-form" onSubmit={onSubmit}>
                    <div className="flex flex-col gap-5 py-8 border-b">
                        <div className="flex items-center gap-2">
                            <Receipt className="w-5 h-5 text-c4" />
                            <h3 className="text-c4 font-medium">{t("payment.payment_method")}</h3>
                        </div>
                        <div>
                            <RadioGroup
                                name="payment"
                                onValueChange={handleOnPaymentMethod}
                                defaultValue={paymentMethod}
                                value={paymentMethod}
                                className="grid grid-cols-1 sm:grid-cols-2">
                                <Label
                                    htmlFor="payment-credit"
                                    className={cn(
                                        "flex items-center space-x-2 px-4 py-6 border rounded-lg cursor-pointer",
                                        paymentMethod === "credit"
                                            ? "bg-c2-100 border-c2 bg-opacity-25"
                                            : "bg-transparent",
                                    )}>
                                    <RadioGroupItem value="credit" id="payment-credit" />
                                    <Image
                                        src={"/assets/icons/payment_credit.svg"}
                                        width={30}
                                        height={30}
                                        alt="payment_credit"
                                    />
                                    <Label htmlFor="payment-credit" className="cursor-pointer select-none">
                                        {t("payment.payment_method_credit")}
                                    </Label>
                                </Label>
                                <Label
                                    htmlFor="payment-cod"
                                    className={cn(
                                        "flex items-center space-x-2 px-4 py-6 border rounded-lg cursor-pointer",
                                        paymentMethod === "cod"
                                            ? "bg-c2-100 border-c2 bg-opacity-25"
                                            : "bg-transparent",
                                    )}>
                                    <RadioGroupItem value="cod" id="payment-cod" />
                                    <Image
                                        src={"/assets/icons/payment_cod.svg"}
                                        width={30}
                                        height={30}
                                        alt="payment_credit"
                                    />
                                    <Label htmlFor="payment-cod" className="cursor-pointer select-none">
                                        {t("payment.payment_method_cod")}
                                    </Label>
                                </Label>
                            </RadioGroup>
                        </div>
                    </div>
                    {/* {(level === "GUEST" || level === "NORMAL") && (
                        <div className="flex flex-col gap-5 py-8 border-b">
                            <div className="flex items-center gap-2">
                                <h3 className="text-c4 font-medium">{t("payment.payment_idCard")}</h3>
                            </div>
                            <Input
                                name="idCard"
                                value={idCard}
                                maxLength={13}
                                inputMode="decimal"
                                className="form-input"
                                placeholder={t("payment.payment_idCard")}
                                onChange={(e) => handleOnIdCardChanged(e.target.value)}
                            />
                            {idCardError && <p className="text-error -mt-2">{idCardError}</p>}
                        </div>
                    )} */}
                    <div className="flex flex-col gap-5 py-8 border-b">
                        <div className="flex items-center gap-2">
                            <MapPin className="w-5 h-5 text-c4" />
                            <h3 className="text-c4 font-medium">{t("payment.payment_receiptAddress")}</h3>
                        </div>
                        <Input
                            name="receiptAddress"
                            value={displayReceiptAddress || ""}
                            className={cn("form-input", !canEditBillingAddress ? "cursor-not-allowed" : "")}
                            placeholder={t("payment.payment_receiptAddress")}
                            onChange={(e) => {
                                setDisplayReceiptAddress(e.target.value);
                                setReceiptAddress(e.target.value);
                            }}
                            readOnly={!canEditBillingAddress}
                        />
                    </div>
                    <div className="flex flex-col gap-5 py-8 border-b">
                        <div className="flex items-center gap-2">
                            <Receipt className="w-5 h-5 text-c4" />
                            <h3 className="text-c4 font-medium">{t("payment.payment_tax_invoice_selection_title")}</h3>
                        </div>
                        <div>
                            <RadioGroup
                                name="tax"
                                onValueChange={handleOnTaxChanged}
                                defaultValue={tax}
                                value={tax}
                                className="grid grid-cols-1 sm:grid-cols-2">
                                <Label
                                    htmlFor="tax-yes"
                                    className={cn(
                                        "flex items-center space-x-2 px-4 py-6 border rounded-lg cursor-pointer",
                                        tax === "yes" ? "bg-c2-100 border-c2 bg-opacity-25" : "bg-transparent",
                                    )}>
                                    <RadioGroupItem value="yes" id="tax-yes" />
                                    <Label htmlFor="tax-yes" className="cursor-pointer select-none">
                                        {t("payment.payment_tax_invoice_selection_yes")}
                                    </Label>
                                </Label>
                                <Label
                                    htmlFor="tax-no"
                                    className={cn(
                                        "flex items-center space-x-2 px-4 py-6 border rounded-lg cursor-pointer",
                                        tax === "no" ? "bg-c2-100 border-c2 bg-opacity-25" : "bg-transparent",
                                    )}>
                                    <RadioGroupItem value="no" id="tax-no" />
                                    <Label htmlFor="tax-no" className="cursor-pointer select-none">
                                        {t("payment.payment_tax_invoice_selection_no")}
                                    </Label>
                                </Label>
                            </RadioGroup>
                        </div>
                    </div>
                    {/* <div className="flex flex-col gap-5 py-8 border-b">
                        <div className="flex items-center gap-2">
                            <ShoppingBag className="w-5 h-5 text-c4" />
                            <h3 className="text-c4 font-medium">{t("payment.payment_shopping_bag_selection_title")}</h3>
                        </div>
                        <div>
                            <RadioGroup
                                name="bag"
                                onValueChange={handleOnBagChanged}
                                defaultValue={bag}
                                value={bag}
                                className="grid grid-cols-1 sm:grid-cols-2">
                                <div
                                    className={cn(
                                        "flex items-center space-x-2 px-4 py-6 border rounded-lg",
                                        bag === "no" ? "bg-c2-100 border-c2" : "bg-transparent",
                                    )}>
                                    <RadioGroupItem value="no" id="bag-no" />
                                    <Label htmlFor="bag-no">{t("payment.payment_shopping_bag_selection_no")}</Label>
                                </div>
                                <div
                                    className={cn(
                                        "flex items-center space-x-2 px-4 py-6 border rounded-lg",
                                        bag === "yes" ? "bg-c2-100 border-c2" : "bg-transparent",
                                    )}>
                                    <RadioGroupItem value="yes" id="bag-yes" />
                                    <Label htmlFor="bag-yes">{t("payment.payment_shopping_bag_selection_yes")}</Label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div> */}
                </form>
            </div>
            <AlertYesNoDialog
                open={openDialog}
                onOpenChange={setOpenDialog}
                title={""}
                description={t("payment.idCard_already_bp")}
                onYes={handleOnContinueForceLogin}
            />
            <AlertOkDialog
                open={openOkDialog}
                onOpenChange={setOpenOkDialog}
                title={""}
                description={t("payment.idCard_found_but_not_bp")}
            />
        </div>
    );
};

export default PaymentInfo;
