import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import Image from "next/image";

const ProductImageFullscreen = ({ imageUrl, className }: { imageUrl: string; className?: string }) => {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Image src={imageUrl} width={480} height={480} alt="thumbnail" className={className} unoptimized />
            </DialogTrigger>
            <DialogContent className="flex md:max-w-3xl sm:max-w-xl xs:max-w-md max-w-xs p-0 rounded-lg border-none max-h-[calc(100vh-36px)]">
                <div className="flex bg-grayc4 rounded-lg">
                    <Image
                        src={imageUrl}
                        width={480}
                        height={480}
                        alt="thumbnail"
                        className="h-full w-full object-contain"
                        unoptimized
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ProductImageFullscreen;
