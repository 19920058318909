import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface State {
    isSocialModelOpen: boolean;
}

const initState: State = {
    isSocialModelOpen: false,
};

const slice = createSlice({
    name: "Application",
    initialState: initState,
    reducers: {
        setSocialModal: (state: State, action: PayloadAction<boolean>) => {
            state.isSocialModelOpen = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

export const { setSocialModal } = slice.actions;
export default slice.reducer;
