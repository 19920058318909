import { createInstance, Namespace, FlatNamespace, KeyPrefix, TFunction } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next/initReactI18next";
import { Language, LocaleAttribute, defaultNS, fallbackLng, getOptions, languages } from "./settings";
import { headers } from "next/dist/client/components/headers";
import { FallbackNs } from "react-i18next";

const initI18next = async (language: Language, ns: string) => {
    const i18nInstance = createInstance();
    await i18nInstance
        .use(initReactI18next)
        .use(
            resourcesToBackend(
                (language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`),
            ),
        )
        .init(getOptions(language, ns));
    return i18nInstance;
};

export async function getTranslation<Ns extends FlatNamespace, KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined>(
    ns: string = defaultNS,
    options: { keyPrefix?: KPrefix } = {},
): Promise<{ t: TFunction<FallbackNs<Ns>, KPrefix>; lang: Language; loc: LocaleAttribute }> {
    const lng = await getLanguage();
    const i18nextInstance = await initI18next(lng, ns);
    return {
        t: i18nextInstance.getFixedT(lng, Array.isArray(ns) ? ns[0] : ns, options.keyPrefix),
        lang: lng,
        loc: lng === "th" ? "TH" : "EN",
    };
}

async function getLanguage(): Promise<Language> {
    const headerList = headers();

    if (headerList.get("x-url")) {
        const xurl = new URL(headerList.get("x-url") ?? "");
        const lngInXurl = languages.find((l) => xurl.pathname.startsWith(`/${l}`));
        return lngInXurl ?? "th";
    }

    return fallbackLng;
}
