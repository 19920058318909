"use client";

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Forward } from "lucide-react";
import { Button } from "../ui/button";
import { iconpath } from "@/util/staticresourcespath";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { getUser } from "@/actions";
import { toast } from "react-hot-toast";
import { copyToClipboard, copyToIOSClipboard } from "@/util/util";
import { useTranslation } from "react-i18next";
interface SocialMedia {
    id: string;
    name: string;
    iconUrl: string;
    linkUrl: string;
}

const socials: SocialMedia[] = [
    {
        id: "Facebook",
        name: "Facebook",
        iconUrl: "Facebook.png",
        linkUrl: "https://www.facebook.com/sharer.php?u={{currentPath}}",
    },
    // {
    //     id: "Messenger",
    //     name: "Messenger",
    //     iconUrl: "Messenger.png",
    //     linkUrl: "",
    // },
    {
        id: "Line",
        name: "Line",
        iconUrl: "LINE_Brand_icon.png",
        linkUrl: "http://line.me/R/msg/text/?{{currentPath}}",
    },
    {
        id: "X",
        name: "X",
        iconUrl: "X.png",
        linkUrl: "https://twitter.com/share?text=&url={{currentPath}}",
    },
    {
        id: "CopyLink",
        name: "Copy Link",
        iconUrl: "copy-link.png",
        linkUrl: "{{currentPath}}",
    },
];

const ProductShareButton = ({ shareLink }: { shareLink?: string }) => {
    const { t } = useTranslation();
    const [currentPath, setCurrentPath] = useState("");
    const [customerAccount, setCustomerAccount] = useState<string | null>();

    useEffect(() => {
        let paramId: string | undefined = undefined;
        if (customerAccount) {
            const cid = customerAccount; //Buffer.from(customerAccount, "utf-8").toString("base64");
            paramId = `cid=${cid}`;
        }
        const queryString = [paramId, "channel=DTC_sharelink"].filter((x) => x).join("&");

        if (shareLink) {
            setCurrentPath(encodeURI(`${shareLink}?${queryString}`));
        } else {
            setCurrentPath(encodeURI(`${window.location.href}?${queryString}`));
        }
    }, [shareLink, customerAccount]);

    useEffect(() => {
        const getCustomerAccount = async () => {
            const user = await getUser();
            if (user && user.level !== "GUEST" && user.level !== "NORMAL") {
                setCustomerAccount(user.customerAccount);
            }
        };
        getCustomerAccount();
    }, []);

    const handleOnClick = async (id: string) => {
        if (id === "CopyLink") {
            try {
                await copyToClipboard(currentPath);
                toast.success("Copied to clipboard", { position: "bottom-center" });
            } catch (err) {
                try {
                    await copyToIOSClipboard(currentPath);
                    toast.success("Copied to clipboard", { position: "bottom-center" });
                } catch (err: any) {
                    toast.error("(3) Failed to copy: " + err.message, { position: "bottom-center" });
                }
            }
            return;
        }
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant={"icon"} size="icon">
                    <Forward size={20} />
                </Button>
            </DialogTrigger>
            <DialogContent className="flex p-0 border-none sm:max-w-[400px] max-w-[300px] bg-white">
                <div className="flex flex-col rounded-lg  shadow-md gap-4 px-6 py-4 w-full h-56">
                    <div className="flex justify-between">
                        <div className="flex flex-row gap-2">
                            <Forward className="text-c2" />
                            <div className="font-font1 text-base">{t("share.btn_share")}</div>
                        </div>
                    </div>
                    <div className="font-normal text-base">
                        {t("share.recommend_business")}
                        <br />
                        {t("share.open_opportunities")}
                    </div>
                    <div className="flex flex-row gap-6">
                        {socials.map((socail: SocialMedia) =>
                            socail.id === "CopyLink" ? (
                                <button
                                    key={socail.id}
                                    type="button"
                                    className="focus:outline-none"
                                    onClick={() => handleOnClick(socail.id)}>
                                    <div className="flex flex-col gap-2 text-center">
                                        <Image
                                            src={iconpath + socail.iconUrl}
                                            alt={""}
                                            width={48}
                                            height={48}
                                            className="self-center"
                                        />
                                        <div className="font-light text-xs">{socail.name}</div>
                                    </div>
                                </button>
                            ) : (
                                <Link
                                    key={socail.id}
                                    href={socail.linkUrl.replace("{{currentPath}}", encodeURIComponent(currentPath))}
                                    target="_blank">
                                    <div className="flex flex-col gap-2 text-center">
                                        <Image
                                            src={iconpath + socail.iconUrl}
                                            alt={""}
                                            width={48}
                                            height={48}
                                            className="self-center"
                                        />
                                        <div className="font-light text-xs">{socail.name}</div>
                                    </div>
                                </Link>
                            ),
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ProductShareButton;
