"use client";

import { useTranslation } from "@/app/i18n/client";
import { ChevronLeftCircle, ChevronRightCircle } from "lucide-react";
import { useRouter, useSearchParams } from "next/navigation";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "@/components/ui/select";
import { cn } from "@/lib/utils";

type Props = {
    currentPage: number;
    totalPage: number;
};

const ArticleListControl = ({ currentPage, totalPage }: Props) => {
    const { t } = useTranslation();

    const router = useRouter();
    const searchParams = useSearchParams();

    const handlePrevious = () => {
        const prePage = currentPage - 1;
        updateParamPage(prePage.toString());
    };

    const handleNext = () => {
        const nxtPage = currentPage + 1;
        updateParamPage(nxtPage.toString());
    };

    const handleOnSelectChanged = (page: string) => {
        updateParamPage(page);
    };

    const updateParamPage = (page: string) => {
        const current = new URLSearchParams(Array.from(searchParams.entries()));

        current.set("blogPage", page);

        const paramQuery = current.toString();
        const query = paramQuery ? `?${paramQuery}` : "";

        router.push(query, {
            scroll: true,
        });
    };

    return totalPage > 0 ? (
        <div
            id="article-pagination"
            className="flex flex-row justify-center items-center gap-4 my-6 text-c4 text-x w-full">
            <button disabled={currentPage <= 1} onClick={handlePrevious} className="disabled:text-grayc3">
                <ChevronLeftCircle className="w-7 h-7" />
            </button>
            {totalPage <= 1 && (
                <p className="text-lg font-normal">
                    {t("product_list.page_current_to_max", { current: currentPage, max: totalPage })}
                </p>
            )}
            {totalPage > 1 && (
                <Select value={`${currentPage}`} onValueChange={handleOnSelectChanged}>
                    <SelectTrigger
                        className={cn("bg-transparent border-0 w-auto text-2xl px-0 whitespace-nowrap gap-2")}>
                        <p className="text-lg font-normal">
                            {t("product_list.page_current_to_max", { current: currentPage, max: totalPage })}
                        </p>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {new Array(totalPage)
                                .fill(null)
                                .map((_, i) => i + 1)
                                .map((item) => (
                                    <SelectItem key={item} value={`${item}`}>
                                        {item}
                                    </SelectItem>
                                ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            )}
            <button disabled={currentPage === totalPage} onClick={handleNext} className="disabled:text-grayc3">
                <ChevronRightCircle className="w-7 h-7" />
            </button>
        </div>
    ) : (
        <div className="flex flex-col w-full items-center gap-2 pb-12">
            <p className="text-xl font-normal">{t("article.not_found")}</p>
        </div>
    );
};

export default ArticleListControl;
