"use client";

import { useTranslation } from "@/app/i18n/client";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import FilterSelectionGroup from "./filter-selection-group";
import { useEffect, useState } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { FilterSelectionGroupItemData } from "./filter-selection-group-item";
import { SlidersHorizontal } from "lucide-react";

export type FilterSourceData = { [key: string]: FilterSelectionGroupItemData[] };
type SelectedFilterType = { [key: string]: string[] };
interface Props {
    className?: string;
    title: string;
    types: string[];
    sourceData: FilterSourceData;
}

const FilterSelection = ({ className, title, types, sourceData }: Props) => {
    const { t } = useTranslation();
    const searchParams = useSearchParams();
    const pathName = usePathname();
    const router = useRouter();

    const [selectedFilters, setSelectedFilters] = useState<SelectedFilterType>({});

    useEffect(() => {
        setCurrentFilterParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCurrentFilterParams = () => {
        let temp: SelectedFilterType = {};
        types.forEach((type) => {
            const filterGroup = searchParams.get(`filter.${type}`)?.split(",") ?? [];
            temp[type] = filterGroup;
        });
        setSelectedFilters(temp);
    };

    const handleOnSelectedFilter = (type: string, filter: string, checked: boolean) => {
        const currentSelectedFilterInType = selectedFilters[type];
        let updateFilter: string[] = [...(currentSelectedFilterInType || [])];
        
        if (checked) {
            if (updateFilter.indexOf(filter) === -1) {
                updateFilter.push(filter);
            }
        } else {
            const index = updateFilter.indexOf(filter);
            if (index >= 0 && index < updateFilter.length) {
                updateFilter.splice(index, 1);
            }
        }
        let newSelectedFilter = {
            ...selectedFilters,
        };
        newSelectedFilter[type] = updateFilter;
        setSelectedFilters(newSelectedFilter);
    };

    const onFilter = () => {
        const searchParams = new URLSearchParams(window.location.search);
        types.forEach((type) => {
            if (selectedFilters[type]) {
                if (selectedFilters[type].length > 0) {
                    searchParams.set(`filter.${type}`, selectedFilters[type].join(","));
                } else {
                    searchParams.delete(`filter.${type}`);
                }
            } else {
                searchParams.delete(`filter.${type}`);
            }
        });
        searchParams.delete("page");
        const newPathName = `${pathName}?${searchParams.toString()}`;
        // router.push(newPathName);
        window.location.href = newPathName;
    };

    const onReset = () => {
        setSelectedFilters({});
    };

    return (
        <Sheet>
            <SheetTrigger
                onClick={setCurrentFilterParams}
                className={cn("bg-transparent border-input rounded-full", className)}
                asChild>
                <Button variant={"outline"}>
                    <p className="text-lg font-normal">{title}</p>
                    <SlidersHorizontal size={16} />
                </Button>
            </SheetTrigger>

            <SheetContent className="overflow-scroll no-scrollbar">
                <SheetHeader className="mt-5">
                    <SheetTitle className="text-xl font-normal text-c4">
                        {t("product_list.filter_options.title")}
                    </SheetTitle>
                </SheetHeader>
                <div className="relative">
                    <div className="flex flex-col gap-6 mt-5">
                        {types.map(
                            (type) =>
                                sourceData[type] && (
                                    <FilterSelectionGroup
                                        key={type}
                                        filterType={type}
                                        selected={selectedFilters[type] ?? []}
                                        onSelectedFilter={handleOnSelectedFilter}
                                        items={sourceData[type]}
                                    />
                                ),
                        )}
                    </div>
                    <div className="absolute top-0 right-0">
                        <Button
                            variant="link"
                            className="h-6 text-c2 hover:no-underline text-sm font-normal focus-visible:ring-0 focus-visible:ring-offset-0"
                            onClick={onReset}>
                            {t("product_list.filter_options.btn_reset")}
                        </Button>
                    </div>
                </div>
                <SheetFooter>
                    <SheetClose asChild className="w-full mt-5">
                        <Button type="submit" variant="outline" onClick={onFilter}>
                            {t("product_list.filter_options.btn_filter")}
                        </Button>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default FilterSelection;
