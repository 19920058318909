"use client";

import { useTranslation } from "@/app/i18n/client";
import { toPriceString } from "@/util/util";
import { Loader2 } from "lucide-react";
import { Button, buttonVariants } from "../ui/button";
import { CartDisplayType } from "./cart-main";
import Link from "next/link";
import { cn } from "@/lib/utils";
import CartShipping from "./cart-shipping";
import { useCart } from "@/lib/providers/cart-provider";

import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { getAddress } from "@/actions/user.address";
import { IUserAddress } from "../../util/query/user-address-query";
import { CUSTOMER_LEVEL } from "@prisma/client";
import { useProfile } from "@/lib/providers/profile-provider";
import CartCoupon from "./cart-coupon";
import CartPromoCode from "./cart-promo-code";

interface Props {
    level: CUSTOMER_LEVEL;
    display: CartDisplayType;
}

const Loading = () => {
    return (
        <div className="flex justify-end">
            <Loader2 className="w-4 h-4 animate-spin text-grayc2" />
        </div>
    );
};

const OrderDetail = ({ display, level }: Props) => {
    const { t, lang } = useTranslation();
    const { cart, isUpdating, addressId, isDoTransaction, isHaveOutOfStockSaleItems } = useCart();
    const { transaction } = useProfile();
    const router = useRouter();
    const [address, setAddress] = useState<IUserAddress>();

    const currency = "฿";
    const discount = 0;
    const fullAddress = `${address?.address.address ?? ""} ${
        lang === "th" ? address?.address.cityTH : address?.address.cityEN ?? ""
    } ${lang === "th" ? address?.address.stateTH : address?.address.stateEN ?? ""} ${address?.address.postcode ?? ""}`;

    useEffect(() => {
        const getUserAddress = async (addressId: string) => {
            const address = await getAddress(addressId);
            if (address) {
                setAddress(address);
            } else {
                router.replace("/checkout");
            }
        };
        if (display === "payment") {
            if (addressId) {
                getUserAddress(addressId);
            }
        }
    }, []);

    return (
        <div className="flex flex-col w-full gap-8 pb-8">
            <h1 className="text-c2 font-medium">{t("cart.order_detail_title")}</h1>
            <div className="w-full flex flex-col gap-6">
                {display !== "payment" && (
                    <CartShipping
                        freeShippingPrice={1500}
                        price={(cart?.final_netAmount ?? 0) - (cart?.final_discount ?? 0)}
                    />
                )}
                {display === "checkout" && level === "GUEST" && <CartPromoCode />}
                {display === "checkout" && <CartCoupon />}
                {display === "payment" && (
                    <div className="p-5 bg-grayc4 rounded-lg text-lg flex flex-col gap-2">
                        <div className="flex flex-row items-baseline text-c4">
                            <dt className="font-light text-grayc3 w-20 flex-shrink-0 flex-grow-0">
                                {t("address.name")}
                            </dt>
                            <dd className="font-normal text-c4 w-full wrapword">{`${address?.address.receiver_name} ${address?.address.receiver_surname}`}</dd>
                        </div>
                        <div className="flex flex-row items-baseline text-c4">
                            <dt className="font-light text-grayc3 w-20 flex-shrink-0 flex-grow-0">
                                {t("address.address")}
                            </dt>
                            <dd className="font-normal text-c4 w-full wrapword">{fullAddress}</dd>
                        </div>
                        <div className="flex flex-row items-baseline text-c4">
                            <dt className="font-light text-grayc3 w-20 flex-shrink-0 flex-grow-0">
                                {t("address.mobile_short")}
                            </dt>
                            <dd className="font-normal text-c4 w-full">{address?.address.receiver_tel ?? "N/A"}</dd>
                        </div>
                    </div>
                )}
                <div className="flex flex-col gap-3 pb-8 border-b">
                    <div className="flex flex-row justify-between items-baseline text-c4">
                        <dt className="text-lg font-normal basis-1/2">{t("cart.order_total_price")}</dt>
                        <dd className="text-xl font-normal basis-1/2 text-end">
                            {isUpdating ? (
                                <Loading />
                            ) : (
                                <div>{`${toPriceString(cart?.final_netAmount ?? 0)} ${currency}`}</div>
                            )}
                        </dd>
                    </div>
                    <div className="flex flex-row justify-between items-baseline text-c4">
                        <dt className="text-lg font-normal basis-1/2">{t("cart.order_discount")}</dt>
                        <dd className="text-xl font-normal basis-1/2 text-end text-discount">
                            {isUpdating ? (
                                <Loading />
                            ) : (
                                <div>{`${(cart?.final_discount ?? 0) > 0 ? "-" : ""}${toPriceString(
                                    cart?.final_discount ?? 0,
                                )} ${currency}`}</div>
                            )}
                        </dd>
                    </div>
                    <div className="flex flex-row justify-between items-baseline text-c4">
                        <dt className="text-lg font-normal basis-1/2">{t("cart.order_delivery_fee")}</dt>
                        <dd className="text-xl font-normal basis-1/2 text-end">
                            {isUpdating ? (
                                <Loading />
                            ) : (
                                <div>{`${toPriceString(cart?.chargeAmount ?? 0)} ${currency}`}</div>
                            )}
                        </dd>
                    </div>
                    {level !== "GUEST" && level !== "NORMAL" && (
                        <div className="flex flex-row justify-between items-baseline text-c4">
                            <dt className="text-lg font-normal basis-1/2">{t("cart.order_total_cvqv")}</dt>
                            <dd className="text-xl font-normal basis-1/2 text-end">
                                {isUpdating ? (
                                    <Loading />
                                ) : (
                                    <div>{`${toPriceString(cart?.final_CV ?? 0)} | ${toPriceString(
                                        cart?.final_QV ?? 0,
                                    )}`}</div>
                                )}
                            </dd>
                        </div>
                    )}
                </div>
                <div className="flex flex-col gap-1 pt-2 pb-8 border-b">
                    <div className="flex flex-row justify-between items-baseline text-c4">
                        <dt className="text-lg font-semibold basis-1/2">{t("cart.order_net_total")}</dt>
                        <dd className="text-xl font-semibold basis-1/2 text-end">
                            {isUpdating ? (
                                <Loading />
                            ) : (
                                <div>{`${toPriceString(cart?.final_price ?? 0)} ${currency}`}</div>
                            )}
                        </dd>
                    </div>
                    {discount > 0 && (
                        <div className="flex flex-row justify-between items-baseline text-grayc3">
                            <dt className="text-lg font-normal basis-1/2">{t("cart.order_save_price")}</dt>
                            <dd className="text-xl font-normal basis-1/2 text-end line-through">
                                {isUpdating ? <Loading /> : <div>{`${toPriceString(0)} ${currency}`}</div>}
                            </dd>
                        </div>
                    )}
                </div>
            </div>
            {display === "cart" && (
                <div className="w-full bg-transparent p-0 left-0 bottom-0">
                    {isHaveOutOfStockSaleItems() ? (
                        <div
                            className={cn(
                                buttonVariants({ variant: "gradient1" }),
                                "w-full h-14 opacity-50 cursor-not-allowed hover:bg-gradient-to-r hover:from-gradient1-start hover:to-gradient1-end hover:text-c2-foreground active:bg-gradient-to-r active:from-gradient1-start active:to-gradient1-end active:text-c2-foreground",
                            )}>
                            {t("cart.order_btn_checkout")}
                        </div>
                    ) : (
                        <Link href="/checkout" className={cn(buttonVariants({ variant: "gradient1" }), "h-14 w-full")}>
                            {t("cart.order_btn_checkout")}
                        </Link>
                    )}
                </div>
            )}
            {display === "checkout" && (
                <div className="w-full bg-transparent p-0 left-0 bottom-0">
                    <Button
                        disabled={isUpdating || transaction || isHaveOutOfStockSaleItems()}
                        isLoading={transaction}
                        form="address-form"
                        type="submit"
                        variant="gradient1"
                        className="h-14 w-full">
                        {t("checkout.btn_payment")}
                    </Button>
                </div>
            )}
            {display === "payment" && (
                <div className="w-full bg-transparent p-0 left-0 bottom-0">
                    <Button
                        disabled={isUpdating || isDoTransaction}
                        isLoading={isDoTransaction}
                        form="payment-form"
                        type="submit"
                        variant="gradient1"
                        className="h-14 w-full">
                        {t("payment.btn_payment")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default OrderDetail;
