import React, { CSSProperties } from "react";
import MaxWidthWrapper from "./maxwidthwrapper";
import { getCollectionByIds } from "@/util/query/collection-query";
import Image from "next/image";
import { getTranslation } from "@/app/i18n";
import { LocaleAttribute } from "@/app/i18n/settings";
import { getShortcutComponent } from "@/util/query/component-query";
import { LinkTarget, WebShortCutType } from "@prisma/client";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import Link from "next/link";
import { auth } from "@/auth";
import HorizontalShortcutItemComponent from "./horizontal-shortcut-item";

export type HorizontalShortcutItem = {
    header?: string;
    title?: string;
    desc?: string;
    image: string | null;
    name: string;
    href: string;

    dispColor1?: string;
    dispColor2?: string;
    dispColor3?: string;
    dispColor4?: string;
    dispGradientAngle?: number;
    dispMaxLine?: number | null;

    descColor1?: string;
    descColor2?: string;
    descColor3?: string;
    descColor4?: string;
    descGradientAngle?: number;
    descMaxLine?: number | null;

    linkTarget?: LinkTarget;

    actionFunc: string | ActionFunc;
};

export enum ActionFunc {
    OPEN_LINK_URL = "OPEN_LINK_URL",
    OPEN_POPUP_REFERRAL = "OPEN_POPUP_REFERRAL",
}

interface HorizontalShortcutComponentProps {
    id: string;
}

const HorizontalShortcutComponent = async ({ id }: HorizontalShortcutComponentProps) => {
    const session = await auth();
    const { loc } = await getTranslation();
    const shortcutComponent = await getShortcutComponent(id, loc);
    if (!shortcutComponent) {
        return null;
    }

    const { type, paramList1, isOverflow } = shortcutComponent;

    const getShortcutData = async (
        queryFrom: WebShortCutType,
        ids: string[],
        loc: LocaleAttribute,
    ): Promise<HorizontalShortcutItem[]> => {
        if (queryFrom === "CATEGOTY") {
            const data = await getCollectionByIds(ids, loc);
            return (
                data.map((item) => {
                    return {
                        image: item.imageUrl,
                        desc: item.attributes.title,
                        name: "",
                        href: `/collections/${item.handle}`,
                        linkTarget: "SELF",
                        actionFunc: ActionFunc.OPEN_LINK_URL,
                    };
                }) ?? []
            );
        } else if (queryFrom === "CUSTOM") {
            return shortcutComponent.items
                .filter((f) => {
                    if (!f.condition) {
                        return true;
                    }

                    if (!f.condition.showWhenLoginOnly) {
                        return true;
                    }

                    return session === null ? false : true;
                })
                .map((item) => {
                    return {
                        header: item.attributes.displayName ?? null,
                        image: item.attributes.imageUrl ?? null,
                        title: item.attributes.displayName ?? null,
                        desc: item.attributes.description ?? null,
                        name: item.attributes.displayName ?? null,
                        href: item.attributes.linkUrl ?? null,
                        dispColor1: item.dispColor1 ?? "",
                        dispColor2: item.dispColor2 ?? "",
                        dispColor3: item.dispColor3 ?? "",
                        dispColor4: item.dispColor4 ?? "",
                        dispMaxLine: item.dispMaxLine ?? null,
                        dispGradientAngle: item.dispGradientAngle ?? 0,
                        descColor1: item.descColor1 ?? "",
                        descColor2: item.descColor2 ?? "",
                        descColor3: item.descColor3 ?? "",
                        descColor4: item.descColor4 ?? "",
                        descGradientAngle: item.descGradientAngle ?? 0,
                        descMaxLine: item.descMaxLine ?? null,
                        linkTarget: item.linkTarget || "SELF",
                        actionFunc: item.condition?.actionFunc || ActionFunc.OPEN_LINK_URL,
                    };
                });
        }

        return [];
    };

    const shortcutItems = await getShortcutData(type, paramList1, loc);

    const generateContainerStyle = (data: any): CSSProperties => {
        const styles: CSSProperties = {};

        // Style
        const { bgColor1, bgColor2, bgColor3, bgColor4 } = data;
        const bgColors = [bgColor1, bgColor2, bgColor3, bgColor4].filter((color) => (color ?? "").length > 0);

        if (bgColors.length > 1) {
            styles["background"] = `linear-gradient(${data.gradientAngle}deg, ${bgColors.join(" ,")})`;
        } else if (bgColors.length > 0) {
            styles["background"] = bgColors[0] ?? "transparent";
        }

        return styles;
    };

    const generateDispStyle = (content: HorizontalShortcutItem): CSSProperties => {
        const styles: CSSProperties = {
            textAlign: "center",
        };

        // Text Style
        const { dispColor1, dispColor2, dispColor3, dispColor4 } = content;
        const colors = [dispColor1, dispColor2, dispColor3, dispColor4].filter((color) => (color ?? "").length > 0);

        if (colors.length > 1) {
            styles["background"] = `linear-gradient(${content.dispGradientAngle}deg, ${colors.join(" ,")})`;
            styles["WebkitBackgroundClip"] = "text";
            styles["WebkitTextFillColor"] = "transparent";
        } else if (colors.length > 0) {
            styles["color"] = colors[0] ?? "transparent";
        }

        return styles;
    };

    const generateDescStyle = (content: HorizontalShortcutItem): CSSProperties => {
        const styles: CSSProperties = {
            textAlign: "center",
        };

        // Text Style
        const { descColor1, descColor2, descColor3, descColor4 } = content;
        const colors = [descColor1, descColor2, descColor3, descColor4].filter((color) => (color ?? "").length > 0);

        if (colors.length > 1) {
            styles["background"] = `linear-gradient(${content.descGradientAngle}deg, ${colors.join(" ,")})`;
            styles["WebkitBackgroundClip"] = "text";
            styles["WebkitTextFillColor"] = "transparent";
        } else if (colors.length > 0) {
            styles["color"] = colors[0] ?? "transparent";
        }

        return styles;
    };

    const shortcutWrapItem = (item: HorizontalShortcutItem) => {
        return (
            <div className="w-full flex justify-center">
                <div className="flex flex-col items-center justify-center gap-3">
                    {item.image ? (
                        <div className="relative">
                            <Image
                                src={item.image}
                                alt={""}
                                width={0}
                                height={0}
                                style={{ width: "100%", height: "auto" }}
                                unoptimized
                            />
                        </div>
                    ) : null}
                    {item.title ? (
                        <div className="text-center" style={generateDispStyle(item)}>
                            <h5
                                style={{
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: item.dispMaxLine ? item.dispMaxLine : "none",
                                }}>
                                {item.title}
                            </h5>
                        </div>
                    ) : null}
                    {item.desc ? (
                        <div className="text-center" style={generateDescStyle(item)}>
                            <h6
                                className="font-light text-c4"
                                style={{
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: item.descMaxLine ? item.descMaxLine : "none",
                                }}>
                                {item.desc}
                            </h6>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <MaxWidthWrapper
            className="py-10"
            sectionWidth={shortcutComponent.sectionWidth}
            contentWidth={shortcutComponent.contentWidth}
            style={generateContainerStyle(shortcutComponent)}>
            <div className="relative flex flex-col gap-7">
                {shortcutComponent.attributes?.displayName ? (
                    <h2 className="text-[40px] font-medium text-c2 text-center">
                        {shortcutComponent.attributes?.displayName}
                    </h2>
                ) : null}
                {isOverflow ? (
                    <Carousel
                        opts={{
                            containScroll: "trimSnaps",
                            dragFree: true,
                        }}
                        className="w-full">
                        <CarouselContent className="justify-between gap-3">
                            {shortcutItems.map((item, index) => (
                                <CarouselItem key={index} className="basis-auto">
                                    <HorizontalShortcutItemComponent
                                        key={index}
                                        item={item}
                                        dispStyle={generateDispStyle(item)}
                                        descStyle={generateDescStyle(item)}
                                    />
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                    </Carousel>
                ) : (
                    <div className="flex flex-row flex-wrap justify-start sm:justify-start lg:justify-around gap-y-4">
                        {shortcutItems.map((item, index) => {
                            if (item.href !== "") {
                                return (
                                    <Link
                                        className="basis-1/2 sm:basis-1/3 lg:flex-1 py-1 px-1 sm:py-2 sm:px-2 lg:pb-0 lg:px-2"
                                        key={index}
                                        href={item.href ?? "#"}>
                                        {shortcutWrapItem(item)}
                                    </Link>
                                );
                            }
                            return (
                                <div
                                    className="basis-1/2 sm:basis-1/3 lg:flex-1 py-1 px-1 sm:py-2 sm:px-2 lg:pb-0 lg:px-2"
                                    key={index}>
                                    {shortcutWrapItem(item)}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </MaxWidthWrapper>
    );
};

export default HorizontalShortcutComponent;
