"use client";

import React from "react";
import { EmblaOptionsType } from "embla-carousel";
import { PrevButton, NextButton, usePrevNextButtons } from "./business-carousel-arrow";
import useEmblaCarousel from "embla-carousel-react";

type PropType = {
    options?: EmblaOptionsType;
    children: React.ReactNode;
    disabledPrevNextButtons?: boolean;
};

const EmblaCarousel: React.FC<PropType> = (props) => {
    const { options, children, disabledPrevNextButtons } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options);

    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

    return (
        <section className="max-w-fit m-auto">
            <div className="overflow-hidden px-4 sm:px-8" ref={emblaRef}>
                <div className="flex gap-4">{children}</div>
            </div>

            {disabledPrevNextButtons ? null : (
                <div className="w-full flex justify-between items-center mt-8">
                    <div className="flex w-full justify-end max-md:justify-center mr-36 max-md:mr-0 gap-3">
                        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                    </div>
                </div>
            )}
        </section>
    );
};

export default EmblaCarousel;
