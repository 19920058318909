"use client";

import { XCircle } from "lucide-react";
import React, { FormEvent, useEffect, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useTranslation } from "@/app/i18n/client";
import { useCart } from "@/lib/providers/cart-provider";
import Image from "next/image";
import { deleteCookie, getCookie } from "cookies-next";

const CartPromoCode = () => {
    const { t } = useTranslation();
    const [coupon, setCoupon] = useState<string>("");
    const { cart, isUpdating, addPromoCode, removePromoCode } = useCart();
    const [disableInput, setDisableInput] = useState<boolean>(cart?.promoCode ? true : false);
    const [error, setError] = useState<string | null | undefined>();

    const handleAddCouponCode = async (e: FormEvent<HTMLFormElement>, counpon: string) => {
        e.preventDefault();
        if (!counpon) {
            return;
        }
        try {
            await addPromoCode(coupon);
            setCoupon("");
        } catch (error: any) {
            setError(error?.message);
        } finally {
            setDisableInput(cart?.promoCode ? true : false);
        }
    };

    const handleRemoveCode = async () => {
        await removePromoCode();
        setDisableInput(false);
        setCoupon("");
    };

    const handleOnChangeText = (text: string) => {
        setCoupon(text);
        setError(undefined);
    };

    useEffect(() => {
        setDisableInput(cart?.promoCode ? true : false);
    }, [cart?.promoCode]);

    useEffect(() => {
        const addSharePromoCode = async (promoCode: string) => {
            try {
                await addPromoCode(promoCode);
            } catch (error: any) {
                setError(error?.message);
            } finally {
                setDisableInput(cart?.promoCode ? true : false);
                deleteCookie("share_cid");
            }
        };

        if (!cart) {
            return;
        }

        const cookie = getCookie("share_cid") as string;
        if (cookie) {
            const promoCode = cookie;// Buffer.from(cookie, "base64").toString("utf-8");
            if (promoCode) {
                if (!cart.promoCode) {
                    addSharePromoCode(promoCode);
                }
            }
        }
    }, []);

    return (
        <div className="p-5 border rounded-lg text-c4 bg-c2-100 bg-opacity-25">
            <div className="flex flex-col gap-4">
                <div className="flex gap-2 items-center">
                    <Image src="/assets/icons/icon-promo-code.svg" alt="promocode" width={35} height={35} />
                    <h5>{t("checkout.promo_code")}</h5>
                </div>
                <form className="flex flex-col gap-4" onSubmit={(e) => handleAddCouponCode(e, coupon)}>
                    <Input
                        disabled={disableInput}
                        value={coupon}
                        onChange={(e) => handleOnChangeText(e.target.value)}
                        className="relative form-input"
                        placeholder={disableInput ? "" : t("checkout.input_placeholder_promo_code")}
                    />
                    {error && (
                        <div>
                            <p className="text-sm font-light text-error">{error}</p>
                        </div>
                    )}
                    {cart?.promoCode && (
                        <div className="absolute mt-[9px] ml-4">
                            <div className="bg-coupon text-coupon-foreground border border-coupon-foreground px-3 py-1 rounded-full flex items-center gap-2">
                                <p className="text-sm font-light">{cart.promoCode}</p>
                                <button type="button" onClick={() => handleRemoveCode()}>
                                    <XCircle size={16} />
                                </button>
                            </div>
                        </div>
                    )}
                    <Button
                        disabled={isUpdating || disableInput || coupon === ""}
                        isLoading={isUpdating}
                        type="submit"
                        variant="outline"
                        className="w-[152px] h-9 text-sm font-normal">
                        {t("checkout.btn_use_promo_code")}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default CartPromoCode;
