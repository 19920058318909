"use client";
import React, { useState } from "react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";

interface Props {
    buttonTitles: string[];
    startIndex?: number;
    onSelectedIndex?: (index: number) => void;
}

const RadioButtonGroup = ({ buttonTitles, startIndex, onSelectedIndex }: Props) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(startIndex ?? 0);

    const handleSelection = (index: number) => {
        setSelectedIndex(index);
        onSelectedIndex?.(index);
    };

    return (
        <div className="flex flex-wrap gap-x-4 gap-y-3">
            {buttonTitles.map((item, index) => (
                <Button
                    key={index}
                    variant="outline"
                    className={cn(
                        "rounded-lg h-11",
                        selectedIndex === index
                            ? "bg-c2 text-c2-foreground hover:text-c2-hover-foreground border-0"
                            : "bg-transparent",
                    )}
                    onClick={() => handleSelection(index)}>
                    {item}
                </Button>
            ))}
        </div>
    );
};

export default RadioButtonGroup;
