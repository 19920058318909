import NextAuth from "next-auth";
import { PrismaAdapter } from "@auth/prisma-adapter";
import { db } from "@/db";
import CredentialsProvider from "next-auth/providers/credentials";
import { compare } from "bcrypt";
import { z } from "zod";
import { CallAPI_SignIn, CallAPI_SignInEmail } from "./util/services/auth/api";
import GoogleProvider from "next-auth/providers/google";
import FacebookProvider from "next-auth/providers/facebook";
import { PrismaClient } from "@prisma/client";
import { Adapter, AdapterUser } from "@auth/core/adapters";
import { SHA256 } from "crypto-js";

let ssoParam: any = null;
const redirectProxyUrl = process.env.NEXT_AUTH_URL_REDIRECT ? process.env.NEXT_AUTH_URL_REDIRECT : undefined;

const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
const GOOGLE_CLIENT_SECRET = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET;

const FACEBOOK_CLIENT_ID = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID;
const FACEBOOK_CLIENT_SECRET = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_SECRET;

function CustomPrismaAdapter(prisma: PrismaClient): Adapter {
    return {
        ...PrismaAdapter(prisma),
        getUserByEmail: async (email: string): Promise<AdapterUser | null> => {
            const user = await prisma.user.findFirst({
                where: {
                    AND: [
                        {
                            level: {
                                not: "GUEST",
                            },
                        },
                        {
                            level: {
                                not: "NORMAL",
                            },
                        },
                    ],
                    email: email,
                },
                include: {},
            });

            if (user) {
                return user as AdapterUser;
            }

            return null;
        },
        createUser: async (user: Omit<AdapterUser, "id">): Promise<AdapterUser> => {
            const userCreated = await prisma.user.create({
                data: {
                    ...user,
                    level: "BP",
                },
            });

            return userCreated as AdapterUser;
        },
    };
}

export const {
    handlers: { GET, POST },
    auth,
    signOut,
    signIn,
} = NextAuth({
    trustHost: true,

    secret: process.env.AUTH_SECRET,
    redirectProxyUrl,

    adapter: CustomPrismaAdapter(db),
    providers: [
        GoogleProvider({
            clientId: GOOGLE_CLIENT_ID,
            clientSecret: GOOGLE_CLIENT_SECRET,
            redirectProxyUrl: redirectProxyUrl,
        }),
        FacebookProvider({
            clientId: FACEBOOK_CLIENT_ID,
            clientSecret: FACEBOOK_CLIENT_SECRET,
            redirectProxyUrl: redirectProxyUrl,
        }),
        CredentialsProvider({
            type: "credentials",
            name: "Credential",
            credentials: {
                username: { type: "text", placeholder: "test", required: true },
                phoneNumber: { type: "text", placeholder: "phoneNumber", required: false }, // not use
                password: { type: "password", placeholder: "Password", required: false }, // not use
                authFlow: { type: "text", placeholder: "test", required: true },
                guestId: { type: "text", placeholder: "", required: false },
                sso: { type: "text", placeholder: "", required: false },
            },
            async authorize(credentials) {
                console.log(`Start Sign in with ${credentials.authFlow}`);
                try {
                    const username: string = credentials.username as string;
                    const phoneNumber: string = credentials.phoneNumber as string;
                    const password: string = credentials.password as string;
                    const authFlow: string = credentials.authFlow as string;
                    const guestId: string = credentials.guestId as string;
                    const sso: string = credentials.sso as string;
                    ssoParam = sso;
                    const loginCognito = await CallAPI_SignIn({
                        username,
                        phoneNumber,
                        password,
                        authFlow,
                        guestId,
                    });

                    console.log("loginCognito", loginCognito);

                    if (loginCognito) return loginCognito.result;

                    // Original Guest
                    // const username: string = credentials.username as string;
                    // let user = await db.user.findFirst({
                    //     where: { email: `${username}@dummy.com` },
                    //     select: { id: true, name: true, email: true },
                    // });

                    // console.log('user',user)

                    // if (!user) {
                    //     user = await db.user.create({
                    //         data: {
                    //             name: username,
                    //             email: `${username}@dummy.com`,
                    //             // isTempUser: true,
                    //         },
                    //         select: { id: true, name: true, email: true },
                    //     });
                    // }

                    // console.log(`\nGuest User:\n${JSON.stringify(user, null, 4)}\n`);
                    // return { ...user };
                } catch (error: unknown) {
                    console.log(`there are some error`, error);
                    return null;
                }
            },
        }),
        //     CredentialsProvider({
        //         id: "sign-in-with-email",
        //         name: "Email",
        //         type: "credentials",
        //         credentials: {
        //             email: { type: "email", placeholder: "Email", required: true },
        //             password: { type: "password", placeholder: "Password", required: true }, // not use
        //         },
        //         async authorize(credentials) {
        //             console.log(`Start Sign in with ---------------------- ${credentials.email}`);
        //             try {
        //                 const email: string = credentials.email as string;
        //                 const password: string = credentials.password as string;

        //                 const loginEmail = await CallAPI_SignInEmail({
        //                     email,
        //                     password,
        //                 });

        //                 if (loginEmail) return loginEmail.result;
        //             } catch (error: unknown) {
        //                 console.log(`there are some error`, error);
        //                 return null;
        //             }
        //         },
        //     }),
    ],
    callbacks: {
        jwt({ token, user }) {
            // console.log(user);
            if (user) {
                return { ...token, id: user.id }; // Save id to token as docs says: https://next-auth.js.org/configuration/callbacks
            }
            return token;
        },
        session: ({ session, token }) => {
            // console.log(`session:::: ${JSON.stringify(session)}| token:::: ${JSON.stringify(token)} `);
            if (token && token.id) {
                return {
                    ...session,
                    user: {
                        ...session.user,
                        // id: user.id, // This is copied from official docs which find user is undefined
                        id: token.id as string, // Get id from token instead
                        sso: ssoParam ? ssoParam : null,
                    },
                };
            }

            return session;
        },
    },
    session: {
        strategy: "jwt",
        maxAge: 180 * 24 * 60 * 60, // 6 months
        updateAge: 24 * 60 * 60, // 24 hours
    },
    // pages: {
    //     signIn: "/signin",
    // },
});
