"use client";

import { getLinkTarget } from "@/util/util";
import { useAppDispatch } from "@/lib/store/hook";
import { setSocialModal } from "@/lib/store/slices/application/application.slice";
import { ActionFunc, HorizontalShortcutItem } from "./horizontal-shortcut";
import Link from "next/link";
import { CSSProperties } from "react";
import Image from "next/image";

interface HorizontalShortcutItemComponentProps {
    item: HorizontalShortcutItem;
    dispStyle: CSSProperties;
    descStyle: CSSProperties;
}

const HorizontalShortcutItemComponent = ({ item, dispStyle, descStyle }: HorizontalShortcutItemComponentProps) => {
    const dispatch = useAppDispatch();

    const openSocialDialog = () => {
        dispatch(setSocialModal(true));
    };

    const generateItem = () => {
        return (
            <div className="flex flex-col justify-start items-center flex-shrink-0 gap-2 first:ml-auto last:mr-auto">
                {item.image && (
                    <Image
                        src={item.image}
                        alt={item.desc || "item.desc"}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                        unoptimized
                    />
                )}
                <div className="flex flex-col">
                    {item.title && (
                        <div className="text-center" style={dispStyle}>
                            <h5 className="line-clamp-2">{item.title}</h5>
                        </div>
                    )}
                    {item.desc && (
                        <div className="text-center" style={descStyle}>
                            <p className="font-light text-c4 line-clamp-2">{item.desc}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (item.actionFunc === ActionFunc.OPEN_LINK_URL && item.href !== "") {
        return (
            <Link href={item.href} target={getLinkTarget(item.linkTarget || "SELF")}>
                {generateItem()}
            </Link>
        );
    }

    if (item.actionFunc === ActionFunc.OPEN_POPUP_REFERRAL) {
        return (
            <div className="hover:cursor-pointer" onClick={openSocialDialog}>
                {generateItem()}
            </div>
        );
    }

    return <div>{generateItem()}</div>;
};

export default HorizontalShortcutItemComponent;
