import { LocaleAttribute } from "@/app/i18n/settings";
import { db } from "@/db";
import {
    BannerItem,
    BannerItemAttb,
    ShortCutItem,
    ShortCutItemAttb,
    WebBannerComponent,
    WebShortCutAttb,
    WebShortCutComponent,
} from "@prisma/client";

export type ShortcutComponentWithAttr = {
    attributes: WebShortCutAttb | null;
    items: ShortCutItem[];
} & WebShortCutComponent;

export const getShortcutComponent = async (id: string, loc: LocaleAttribute) => {
    const data = await db.webShortCutComponent
        .findUnique({
            where: {
                id: id,
            },
            include: {
                attributes: true,
                items: {
                    orderBy: {
                        priority: "asc",
                    },
                    include: {
                        attributes: true,
                        condition: true,
                    },
                },
            },
        })
        .then((result) => {
            if (result) {
                const attrs = result.attributes.filter((attr) => attr.loc === loc);
                return {
                    ...result,
                    attributes:
                        attrs.length > 0
                            ? attrs[0]
                            : result.attributes.length > 0
                              ? result.attributes[0]
                              : ({} as ShortCutItemAttb),
                    items: result.items.map((item) => {
                        const attrsItem = item.attributes.filter((attr) => attr.loc === loc);

                        return {
                            ...item,
                            attributes: attrsItem.length === 0 ? ({} as ShortCutItemAttb) : attrsItem[0],
                        };
                    }),
                };
            }
            return result;
        });

    return data;
};

export type AdsBannerComponentItemWithAttr = {
    attributes: BannerItemAttb | null;
} & BannerItem;

export type AdsBannerComponentWithItems = {
    items: AdsBannerComponentItemWithAttr[];
} & WebBannerComponent;

export const getAdsBannerComponent = async (id: string, loc: LocaleAttribute): Promise<AdsBannerComponentWithItems | null> => {
    const data = await db.webBannerComponent
        .findUnique({
            where: {
                id: id,
            },
            include: {
                items: {
                    include: {
                        attributes: true,
                    },
                },
            },
        })
        .then((result) => {
            if (result) {
                return {
                    ...result,
                    items: [
                        ...result.items.map((item) => {
                            const attrs = item.attributes.filter((attr) => attr.loc === loc);
                            return {
                                ...item,
                                attributes:
                                    attrs.length > 0
                                        ? attrs[0]
                                        : item.attributes.length > 0
                                          ? item.attributes[0]
                                          : null,
                            };
                        }),
                    ],
                };
            }
            return result;
        });
    return data;
};

export const getHorizontalTextComponent = async (id: string, loc: LocaleAttribute) => {
    const data = await db.webTextLabelComponent
        .findUnique({
            where: {
                id: id,
            },
            include: {
                attributes: true,
            },
        })
        .then((result) => {
            if (result) {
                const attrs = result.attributes.filter((attr) => attr.loc === loc);
                return {
                    ...result,
                    attributes:
                        attrs.length > 0 ? attrs[0] : result.attributes.length > 0 ? result.attributes[0] : null,
                };
            }
            return result;
        });

    return data;
};
