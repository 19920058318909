"use client";

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Image from "next/image";
import { iconpath } from "@/util/staticresourcespath";
import { DateToFormatWithTimeZone } from "@/util/date-format";
import { LocaleAttribute } from "@/app/i18n/settings";

type Event = {
    id: string;
    displayName: string;
    desktopImageUrl: string;
    mobileImageUrl: string;
    imageBackgroundColor: string;
    description: string;
    location: string;
    introList: string[];
    startDateTime: string;
    endDateTime: string;
};

type Props = {
    loc: string;
    event: Event;
};

const DialogEvent = (props: Props) => {
    const { event, loc } = props;

    return (
        <Dialog.Root key={event.id}>
            <Dialog.Trigger asChild>
                <span className="w-full font-medium text-sm text-wrap whitespace-pre-line text-c2 cursor-pointer hover:text-white">
                    {event.displayName}
                </span>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed bg-black bg-opacity-20 inset-0 z-10" />
                <Dialog.Content className="fixed outline-none flex flex-col bg-white z-[9999] h-dvh sm:h-auto top-0 sm:top-[20%] sm:bottom-[20%] sm:left-[10%] sm:right-[10%] lg:left-[15%] lg:right-[15%] sm:m-auto">
                    <div className="flex-1 flex flex-col sm:flex-row h-full">
                        <div
                            className="relative flex justify-center items-center w-full sm:w-96 h-[350px] sm:h-full"
                            style={{
                                backgroundColor: event.imageBackgroundColor,
                            }}>
                            <Image
                                src={event.desktopImageUrl}
                                alt={event.displayName}
                                width={0}
                                height={0}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                unoptimized
                                className="max-sm:hidden"
                                onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = "https://dummyimage.com/350x600/e9e9e9/e9e9e9.jpg";
                                }}
                            />
                            <Image
                                src={event.mobileImageUrl}
                                alt={event.displayName}
                                width={0}
                                height={0}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                unoptimized
                                className="sm:hidden"
                                onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = "https://dummyimage.com/400x350/e9e9e9/e9e9e9.jpg";
                                }}
                            />
                        </div>
                        <div className="flex flex-col p-4 sm:p-10 gap-4 w-full sm:w-[70%] sm:h-full overflow-y-auto sm:overflow-y-hidden">
                            <span className="font-medium text-xl text-wrap text-black">{event.displayName}</span>

                            <div className="flex flex-col gap-2">
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="relative w-fit">
                                        <Image
                                            src={iconpath + "location.svg"}
                                            alt={"location"}
                                            width={0}
                                            height={0}
                                            style={{ width: "100%", height: "auto" }}
                                            unoptimized
                                        />
                                    </div>
                                    <span className="font-light text-base text-wrap text-black">{event.location}</span>
                                </div>

                                <div className="flex flex-wrap gap-2">
                                    <div className="flex flex-row gap-2 items-center mr-10">
                                        <div className="relative w-[20px]">
                                            <Image
                                                src={iconpath + "clock.svg"}
                                                alt={"clock"}
                                                width={0}
                                                height={0}
                                                style={{ width: "100%", height: "auto", objectFit: "cover" }}
                                                unoptimized
                                            />
                                        </div>

                                        <span className="font-light text-base text-wrap text-black">
                                            {DateToFormatWithTimeZone(
                                                new Date(event.startDateTime),
                                                loc as LocaleAttribute,
                                                "d MMMM yyyy",
                                            )}
                                            ,&nbsp;
                                            {DateToFormatWithTimeZone(
                                                new Date(event.startDateTime),
                                                loc as LocaleAttribute,
                                                "hh:mm",
                                            )}
                                            &nbsp;-&nbsp;
                                            {DateToFormatWithTimeZone(
                                                new Date(event.endDateTime),
                                                loc as LocaleAttribute,
                                                "hh:mm a",
                                            )}
                                        </span>
                                    </div>

                                    <div className="flex flex-row gap-2 items-center">
                                        <div className="relative w-fit">
                                            <Image
                                                src={iconpath + "clock.svg"}
                                                alt={"clock"}
                                                width={0}
                                                height={0}
                                                style={{ width: "100%", height: "auto", objectFit: "cover" }}
                                                unoptimized
                                            />
                                        </div>

                                        <span className="font-light text-base text-wrap text-black">
                                            {event.introList.join(", ")}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:overflow-y-auto">
                                <span className="font-light text-base text-wrap text-black">{event.description}</span>
                            </div>
                        </div>
                    </div>

                    <Dialog.Close asChild>
                        <div className="inline-flex absolute top-0 right-0 w-17 h-17 p-4 z-10">
                            <Image
                                src={iconpath + "Union.svg"}
                                alt={"Union"}
                                width={0}
                                height={0}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                className="cursor-pointer max-sm:fill-white"
                                unoptimized
                            />
                        </div>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default DialogEvent;
