"use client";

import { cn } from "@/lib/utils";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface Props {
    onPrevious?: () => void;
    onNext?: () => void;
    disabledPrevious?: boolean;
    disabledNext?: boolean;
    className?: string;
    buttonClassName?: string;
}
const CarouselPageControl = ({
    onPrevious,
    onNext,
    disabledPrevious,
    disabledNext,
    className,
    buttonClassName,
}: Props) => {
    return (
        <div className={cn("flex gap-4", className)}>
            <div className="">
                <button
                    className={cn(
                        "flex items-center justify-center bottom-4 right-20 rounded-full w-8 h-8 text-c4 bg-white disabled:text-grayc3",
                        buttonClassName,
                    )}
                    onClick={onPrevious}
                    disabled={disabledPrevious ?? false}>
                    <ChevronLeft />
                </button>
            </div>
            <div className="">
                <button
                    className={cn(
                        "flex items-center justify-center rounded-full w-8 h-8 text-c4 bg-white disabled:text-grayc3",
                        buttonClassName,
                    )}
                    onClick={onNext}
                    disabled={disabledNext ?? false}>
                    <ChevronRight />
                </button>
            </div>
        </div>
    );
};

export default CarouselPageControl;
