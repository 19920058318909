import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/old-site.css");
import(/* webpackMode: "eager" */ "/app/src/components/ads/ads-section/components/ads-banner-carousel.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/articles/articlecard.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/articles/category-tab/article-list-control.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/business-video.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/components/business-carousel.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/earning-from-ulife/components/dialog-details.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/event-calendar/components/calendar.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/hall-of-fame/components/dialog-person.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/how-good-to-be-ulife-member/components/dialog-image.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/innovation/rewards.css");
import(/* webpackMode: "eager" */ "/app/src/components/cart/cart-main.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/controls/favorite-button.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/faq/component/expand.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/faq/top-faqs.css");
import(/* webpackMode: "eager" */ "/app/src/components/forms/contact-us/contact-us-form.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/horizontal-shortcut-item.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/how-to-shop/step-information.css");
import(/* webpackMode: "eager" */ "/app/src/components/page-control.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/payment/payment-main.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/products/product-collapsible-detail.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/products/product-header-info.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/products/product-promotion-header.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/products/product-related-list.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/RemoteSVG.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/selections/filter-selection.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/selections/order-selection.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/share-cookie/share-cookie.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui/carousel.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui/separator.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui/Tooltip/custom-tooltip.tsx")