import { getTranslation } from "@/app/i18n";
import { MSProductWithAttribute, MSProductWithFullDetail } from "@/util/query/product-query";
import Image from "next/image";
import Link from "next/link";
import { buttonVariants } from "../ui/button";
import { toPriceString } from "@/util/util";
import { TFunction } from "i18next";
import { auth } from "@/auth";
import { CUSTOMER_LEVEL } from "@prisma/client";
import FavoriteButton from "../controls/favorite-button";
import { cn } from "@/lib/utils";
import { CustomTooltip } from "../ui/Tooltip/custom-tooltip";

interface CollectionListItemProps {
    product: MSProductWithFullDetail;
    href: string;
    t: TFunction<"translation", undefined>;
    level: CUSTOMER_LEVEL;
    refreshfav?: boolean;
    variantId?: string;
}

const CollectionListItem = ({ product, href, t, level, refreshfav, variantId }: CollectionListItemProps) => {
    let variant = product.productVariant.length > 0 ? product.productVariant[0] : null;
    if (variantId) {
        const variantsFilter = product.productVariant.filter((x) => x.id === variantId);
        if (variantsFilter.length > 0) {
            variant = variantsFilter[0];
        }
    }
    const title = product.attributes?.displayName ?? "";
    const currency = product.attributes?.shortCurrency || "฿";

    let cv: number | null;
    let qv: number | null;
    if (level === "BP_VVIP") {
        cv = variant ? variant.CV_VVIP : null;
        qv = variant ? variant.QV_VVIP : null;
    } else if (level === "BP_VIP") {
        cv = variant ? variant.CV_VIP : null;
        qv = variant ? variant.QV_VIP : null;
    } else {
        cv = variant ? variant.CV : null;
        qv = variant ? variant.QV : null;
    }

    const vvipNormalPrice = variant ? variant.priceVVIP : null;
    const vipNormalPrice = variant ? variant.priceVIP : null;
    const trialNormalPrice = variant ? variant.priceTrial : null;
    const normalPrice = variant ? variant.priceFull : null;
    const isVVIPHaveSpecialPrice = variant
        ? variant.comparePriceVVIP
            ? variant.comparePriceVVIP < variant.priceVVIP
            : false
        : false;
    const isVIPHaveSpecialPrice = variant
        ? variant.comparePriceVIP
            ? variant.comparePriceVIP < variant.priceVIP
            : false
        : false;
    const isTrialHaveSpecialPrice = variant
        ? variant.comparePriceTrial
            ? variant.comparePriceTrial < variant.priceTrial
            : false
        : false;
    const isFullHaveSpecialPrice = variant
        ? variant.comparePriceFull
            ? variant.comparePriceFull < variant.priceFull
            : false
        : false;
    const vvipPrice = isVVIPHaveSpecialPrice
        ? variant
            ? variant.comparePriceVVIP
            : null
        : variant
          ? variant.priceVVIP
          : null;
    const vipPrice = isVIPHaveSpecialPrice
        ? variant
            ? variant.comparePriceVIP
            : null
        : variant
          ? variant.priceVIP
          : null;
    const trialPrice = isTrialHaveSpecialPrice
        ? variant
            ? variant.comparePriceTrial
            : null
        : variant
          ? variant.priceTrial
          : null;
    const price = isFullHaveSpecialPrice
        ? variant
            ? variant.comparePriceFull
            : null
        : variant
          ? variant.priceFull
          : null;

    const imageUrl = variant ? variant.featuredImageUrl : product.featuredImageUrl;

    return (
        <div className="flex flex-col justify-start items-center gap-2 w-full">
            <div className="relative bg-grayc4 rounded-lg w-full flex justify-center items-center aspect-square">
                <Link href={href}>
                    <Image src={imageUrl ?? ""} alt={title} unoptimized fill />
                </Link>
                {/* <div className="p-1 bg-white rounded-full w-7 h-7 absolute top-2 right-2"> */}
                {/* <span className="material-symbols-outlined text-lg w-full text-center">favorite</span> */}
                <FavoriteButton
                    className="w-7 h-7 absolute top-2 right-2 bg-white text-c4 p-1.5"
                    productHandle={product.handle}
                    refresh={refreshfav}
                />
                {/* </div> */}
            </div>
            <div className="flex flex-col w-full gap-2">
                <div>
                    <Link href={href} className="hover:text-c2">
                        <h3 className="text-sm font-normal line-clamp-4">{title}</h3>
                    </Link>
                </div>
                <div>
                    <dl className="flex flex-col ">
                        {level === "BP_VVIP" && (
                            <div className="flex flex-row justify-between items-baseline">
                                <dt className="text-[12px] font-normal text-c4 basis-1/2">
                                    {t("product_list.vvip_subscription_price")}
                                </dt>
                                <dd className="text-lg font-medium text-vvip basis-1/2 text-end">
                                    <span className="text-sm font-light line-through text-grayc3 mr-1.5">
                                        {isVVIPHaveSpecialPrice && vvipNormalPrice
                                            ? `${toPriceString(vvipNormalPrice)}`
                                            : ""}
                                    </span>
                                    {vvipPrice ? `${toPriceString(vvipPrice)} ${currency}` : ""}
                                </dd>
                            </div>
                        )}

                        {/* Display VIP Price except VVIP */}
                        {level === "BP_VIP" && (
                            <div className="flex flex-row justify-between items-baseline">
                                <dt className="text-[12px] font-normal text-c4 basis-1/2">
                                    {t("product_list.vip_subscription_price")}
                                </dt>
                                <dd className="text-lg font-medium text-price basis-1/2 text-end">
                                    <span className="text-sm font-light line-through text-grayc3 mr-1.5">
                                        {isVIPHaveSpecialPrice && vipNormalPrice
                                            ? `${toPriceString(vipNormalPrice)}`
                                            : ""}
                                    </span>
                                    {vipPrice ? `${toPriceString(vipPrice)} ${currency}` : ""}
                                </dd>
                            </div>
                        )}

                        {/* Display Trial Price except VIP and VVIP */}
                        {(level === "NORMAL" || level === "GUEST" || level === "BP" || level === "BF") && (
                            <div className="flex flex-row justify-between items-baseline">
                                <dt className="text-[12px] font-normal text-c4 basis-1/2">
                                    <div className="flex justify-start items-center gap-1">
                                        {t("product_list.trial_price")}
                                        <CustomTooltip text={t("product.trial_tip")} />
                                    </div>
                                </dt>
                                <dd className="text-sm font-light text-c4 basis-1/2 text-end">
                                    <span className="text-sm font-light line-through text-grayc3 mr-1.5">
                                        {isTrialHaveSpecialPrice && trialNormalPrice
                                            ? `${toPriceString(trialNormalPrice)}`
                                            : ""}
                                    </span>
                                    {trialPrice ? `${toPriceString(trialPrice)} ${currency}` : ""}
                                </dd>
                            </div>
                        )}

                        {/* Display Full price and line through except guest or normal not line through */}
                        <div className="flex flex-row justify-between items-baseline">
                            <dt className="text-[12px] font-normal text-c4 basis-1/2">{t("product_list.price")}</dt>
                            <dd
                                className={cn(
                                    "text-sm font-light text-grayc3 basis-1/2 text-end",
                                    level !== "GUEST" && level !== "NORMAL" ? "line-through" : "",
                                )}>
                                <span className="text-sm font-light line-through text-grayc3 mr-1.5">
                                    {isFullHaveSpecialPrice && normalPrice ? `${toPriceString(normalPrice)}` : ""}
                                </span>
                                {price ? `${toPriceString(price)} ${currency}` : ""}
                            </dd>
                        </div>
                        {(level === "BP_VVIP" || level === "BP_VIP" || level === "BP") && (
                            <div className="flex flex-row justify-between items-baseline">
                                <dt className="text-[12px] font-normal text-c4 basis-1/2">{t("product_list.cvqv")}</dt>
                                <dd className="text-sm font-light text-c4 basis-1/2 text-end">
                                    {`${toPriceString(cv ?? 0)} | ${toPriceString(qv ?? 0)}`}
                                </dd>
                            </div>
                        )}
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default CollectionListItem;
