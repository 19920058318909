import { MSProductVariantWithAttribute } from "@/util/query/product-query";
import React from "react";
import { Separator } from "../ui/separator";
import { Crown, Info } from "lucide-react";

import { useTranslation } from "@/app/i18n/client";
import { cn } from "@/lib/utils";
import { toPriceString } from "@/util/util";
import { CustomTooltip } from "../ui/Tooltip/custom-tooltip";

export type ProductPriceGroupType =
    | "vvip"
    | "vip"
    | "trial"
    | "normal"
    | "qv"
    | "cv"
    | "qv_vip"
    | "cv_vip"
    | "qv_vvip"
    | "cv_vvip";

interface Props {
    variant: MSProductVariantWithAttribute | undefined | null;
    type: ProductPriceGroupType;
    hideSeperator?: boolean;
    lineThrough?: boolean;
    isGuest?: boolean;
}

export const ProductPriceGroupTitle = ({ type }: { type: ProductPriceGroupType }) => {
    const { t } = useTranslation();
    const getTitle = () => {
        switch (type) {
            case "vvip":
                return t("product.vvip_subscription_price");
            case "vip":
                return t("product.vip_subscription_price");
            case "trial":
                return t("product.trial_price");
            case "normal":
                return t("product.price");
            case "cv":
                return t("product.cv");
            case "qv":
                return t("product.qv");
            case "cv_vip":
                return t("product.cv");
            case "qv_vip":
                return t("product.qv");
            case "cv_vvip":
                return t("product.cv");
            case "qv_vvip":
                return t("product.qv");
        }
    };

    const title = getTitle();
    if (type === "vip" || type === "vvip") {
        return (
            <div className="flex justify-start items-center gap-1">
                <Crown size={16} />
                <h5>{title}</h5>
            </div>
        );
    } else if (type === "trial") {
        return (
            <div className="flex justify-start items-center gap-1">
                <h5>{title}</h5>
                <CustomTooltip text={t("product.trial_tip")} />
            </div>
        );
    } else {
        return (
            <div className="flex justify-start items-center gap-1">
                <h5>{title}</h5>
            </div>
        );
    }
    return null;
};

const ProductPriceGroup = ({ variant, type, hideSeperator, lineThrough, isGuest = false }: Props) => {
    const { t, loc } = useTranslation();

    if (!variant) {
        return null;
    }

    const getTextColor = () => {
        if (isGuest) {
            switch (type) {
                case "trial":
                    return "text-grayc3";
                case "normal":
                    return "text-c4";
            }
        }

        switch (type) {
            case "vvip":
                return "text-vvip";
            case "vip":
                return "text-price";
            case "trial":
                return "text-c4";
            case "normal":
                return "text-grayc3";
        }
    };

    const getShortCurrency = () => {
        const attr = variant.attributes?.filter((atr) => atr.loc === loc);
        const otherAttr = variant.attributes?.filter((attr) => attr.loc !== loc);
        let title: string | null = "";
        if (attr && attr.length > 0) {
            title = attr[0].shortCurrency;
        }
        if (!title) {
            if (otherAttr && otherAttr.length > 0) {
                title = otherAttr[0].shortCurrency;
            }
        }
        return title ?? "฿";
    };

    const getValue = () => {
        const shortCurrency = getShortCurrency();
        switch (type) {
            case "vvip":
                return `${toPriceString(variant.priceVVIP)} ${shortCurrency}`;
            case "vip":
                return `${toPriceString(variant.priceVIP)} ${shortCurrency}`;
            case "trial":
                return `${toPriceString(variant.priceTrial)} ${shortCurrency}`;
            case "normal":
                return `${toPriceString(variant.priceFull)} ${shortCurrency}`;
            case "cv":
                return `${toPriceString(variant.CV)}`;
            case "qv":
                return `${toPriceString(variant.QV)}`;
            case "cv_vip":
                return `${toPriceString(variant.CV_VIP)}`;
            case "qv_vip":
                return `${toPriceString(variant.QV_VIP)}`;
            case "cv_vvip":
                return `${toPriceString(variant.CV_VVIP)}`;
            case "qv_vvip":
                return `${toPriceString(variant.QV_VVIP)}`;
        }
    };

    const getSpecialValue = () => {
        const shortCurrency = getShortCurrency();
        switch (type) {
            case "vvip":
                return variant.comparePriceVVIP ? `${toPriceString(variant.comparePriceVVIP)} ${shortCurrency}` : "";
            case "vip":
                return variant.comparePriceVIP ? `${toPriceString(variant.comparePriceVIP)} ${shortCurrency}` : "";
            case "trial":
                return variant.comparePriceTrial ? `${toPriceString(variant.comparePriceTrial)} ${shortCurrency}` : "";
            case "normal":
                return variant.comparePriceFull ? `${toPriceString(variant.comparePriceFull)} ${shortCurrency}` : "";
        }
        return "";
    };

    const isHaveSpecialPrice = () => {
        switch (type) {
            case "vvip":
                return variant.comparePriceVVIP ? variant.comparePriceVVIP < variant.priceVVIP : false;
            case "vip":
                return variant.comparePriceVIP ? variant.comparePriceVIP < variant.priceVIP : false;
            case "trial":
                return variant.comparePriceTrial ? variant.comparePriceTrial < variant.priceTrial : false;
            case "normal":
                return variant.comparePriceFull ? variant.comparePriceFull < variant.priceFull : false;
        }
        return false;
    };

    return (
        <div className={cn("flex items-center", getTextColor())}>
            <div className="flex flex-col">
                <ProductPriceGroupTitle type={type} />
                <div>
                    <h3 className={cn("font-medium", lineThrough ? "line-through" : "")}>
                        {isHaveSpecialPrice() ? getSpecialValue() : getValue()}
                    </h3>
                </div>
                <div>
                    <p className="font-medium text-xs line-through">{isHaveSpecialPrice() ? getValue() : ""}</p>
                </div>
            </div>
            <Separator
                orientation="vertical"
                className="h-full text-c2 mx-4"
                decorative={false}
                hidden={hideSeperator ?? false}
            />
        </div>
    );
};

export default ProductPriceGroup;
