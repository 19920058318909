"use client";

import { cn } from "@/lib/utils";
import MaxWidthWrapper from "../maxwidthwrapper";
import OrderDetail from "../cart/order-detail";
import PaymentInfo from "./payment-info";
import { useEffect, useState } from "react";
import * as actions from "@/actions";
import { CUSTOMER_LEVEL } from "@prisma/client";
import { useSession } from "next-auth/react";
import { Loader2 } from "lucide-react";

const PaymentMain = () => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [level, setLevel] = useState<CUSTOMER_LEVEL>("GUEST");

    const session = useSession();

    useEffect(() => {
        const getUserLevel = async () => {
            const user = await actions.getUser();

            setLevel(user?.level ?? "GUEST");
            setLoading(false);
        };

        if (session.status !== "loading") {
            if (session.data?.user) {
                setLoading(true);
                getUserLevel();
            } else {
                setLoading(false);
            }
        }
    }, [session.status]);

    return (
        <MaxWidthWrapper className="py-8 2xl:px-0">
            {isLoading ? (
                <div className={cn("w-full h-full justify-center items-center", isLoading ? "flex" : "hidden")}>
                    <Loader2 className="animate-spin w-8 h-8" />
                </div>
            ) : (
                <div className={cn("grid-cols-1 lg:grid-cols-10 w-full h-full lg:gap-16 gap-8", "grid")}>
                    <div className="flex lg:col-span-7">
                        <PaymentInfo />
                    </div>
                    <div className="flex w-full lg:col-span-3">
                        <OrderDetail display={"payment"} level={level} />
                    </div>
                </div>
            )}
        </MaxWidthWrapper>
    );
};

export default PaymentMain;
