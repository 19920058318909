"use client";

import React, { CSSProperties } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Model } from "../hall-of-fame-list";
import Image from "next/image";
import { iconpath } from "@/util/staticresourcespath";
import { useTranslation } from "@/app/i18n/client";

type Props = {
    model: Model;
};

const DialogPerson = (props: Props) => {
    const { t } = useTranslation();

    const generateContainerStyle = (data: any): CSSProperties => {
        const styles: CSSProperties = {};

        // Style
        const { dialogBgColor1, dialogBgColor2, dialogBgColor3, dialogBgColor4 } = data;
        const bgColors = [dialogBgColor1, dialogBgColor2, dialogBgColor3, dialogBgColor4].filter(
            (color) => (color ?? "").length > 0,
        );

        if (bgColors.length > 1) {
            styles["background"] = `linear-gradient(${data.dialogGradientAngle}deg, ${bgColors.join(" ,")})`;
        } else if (bgColors.length > 0) {
            styles["background"] = bgColors[0] ?? "transparent";
        }

        return styles;
    };

    return (
        <Dialog.Root key={props.model.id}>
            <Dialog.Trigger asChild>
                <span className="font-medium text-sm text-wrap whitespace-pre-line text-c2 underline cursor-pointer hover:text-grayc3">
                    {t("hall_of_fame.read_more")}
                </span>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed bg-black bg-opacity-20 inset-0 z-10" />
                <Dialog.Content className="fixed outline-none flex flex-col bg-white z-[9999] h-dvh sm:h-auto top-0 sm:top-[20%] sm:bottom-[5%] sm:left-[10%] sm:right-[10%] sm:m-auto mb-8">
                    <div className="flex flex-col sm:flex-row h-full">
                        <div
                            className="relative w-full sm:w-[30rem] h-[40%] sm:h-full"
                            style={generateContainerStyle(props.model)}>
                            <Image
                                src={props.model.imageUrl}
                                alt={props.model.imageAlt || ""}
                                width={0}
                                height={0}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                className="object-top sm:object-left-top sm:pt-5"
                                unoptimized
                            />
                        </div>
                        <div className="flex flex-col p-4 sm:p-10 gap-2 sm:w-[70%] h-[60%] sm:h-full">
                            <h3 className="font-medium text-2xl text-wrap text-black">{props.model.name}</h3>

                            <div className="flex flex-wrap gap-2">
                                <h4 className="font-normal text-xl text-wrap whitespace-pre-line text-black">
                                    {props.model.dialogSubtitle}
                                </h4>
                            </div>

                            <div className="overflow-y-auto">
                                <p className="font-light text-base text-wrap whitespace-pre-line text-black">
                                    {props.model.allDes}
                                </p>
                            </div>
                        </div>
                    </div>

                    <Dialog.Close asChild>
                        <div className="inline-flex absolute top-0 right-0 w-17 h-17 p-4 z-10">
                            <Image
                                src={iconpath + "Union.png"}
                                alt={"Union"}
                                width={0}
                                height={0}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                className="cursor-pointer"
                                unoptimized
                            />
                        </div>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default DialogPerson;
