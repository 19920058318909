import { useTranslation } from "@/app/i18n/client";
import { CheckCircle2, X } from "lucide-react";
import Image from "next/image";
import React from "react";
import { MSProductVariantWithAttribute, MSProductWithFullDetail } from "../../util/query/product-query";
import ProductPriceGroup, { ProductPriceGroupType } from "../products/product-price-group";
import { Button, buttonVariants } from "../ui/button";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { useCart } from "@/lib/providers/cart-provider";

interface Props {
    visible: boolean;
    onClose?: () => void;
    product: MSProductWithFullDetail;
    variant: MSProductVariantWithAttribute;
    type: ProductPriceGroupType;
}

const CartNotification = ({ visible, onClose, product, variant, type }: Props) => {
    const { t, loc } = useTranslation();
    const { numItemsInCart } = useCart();

    const numCartItems = numItemsInCart();

    const getVariantTitle = () => {
        const attr = variant.attributes?.filter((atr) => atr.loc === loc);
        const otherAttr = variant.attributes?.filter((attr) => attr.loc !== loc);
        let title: string | null = "";
        if (attr && attr.length > 0) {
            title = attr[0].opttion1;
        }
        if (!title) {
            if (otherAttr && otherAttr.length > 0) {
                title = otherAttr[0].opttion1;
            }
        }
        return title ?? "";
    };

    const variantTitle = getVariantTitle();

    console.log(`cart-notification: ${numCartItems}`);
    return (
        <div
            className={`${
                visible ? "animate-enter" : "animate-leave"
            } max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 p-5`}>
            <div className="w-full flex flex-col gap-4">
                <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                        <CheckCircle2 color="#00b812" />
                        <h3 className="font-medium text-c4">{t("cart.add_to_cart")}</h3>
                    </div>
                    <button>
                        <X onClick={onClose} />
                    </button>
                </div>
                <div className="flex items-start gap-4">
                    <div className="aspect-square bg-grayc4 rounded-sm p-2 flex">
                        <Image
                            src={variant.featuredImageUrl ?? product.featuredImageUrl ?? ""}
                            alt="product image"
                            width={80}
                            height={80}
                            className="object-contain"
                            unoptimized
                        />
                    </div>
                    <div className="w-full flex">
                        <div className="flex flex-col text-c4 gap-2">
                            <h5>{product.attributes?.displayName}</h5>
                            <div className="flex">
                                <h6 className="font-light">{`${variantTitle}  ${t("product.weight", {
                                    weight: variant.weight,
                                })}`}</h6>
                            </div>
                            <ProductPriceGroup variant={variant} type={type} hideSeperator={true} />
                        </div>
                    </div>
                </div>
                <div className="inline-flex justify-between items-center gap-4 w-full">
                    <Link
                        href="/cart"
                        onClick={onClose}
                        className={cn(buttonVariants({ variant: "gradient1" }), "h-11 w-full")}>
                        {t("cart.btn_view_cart", { numItems: numCartItems > 0 ? `(${numCartItems})` : "" })}
                    </Link>
                    <Link
                        href="/checkout"
                        onClick={onClose}
                        className={cn(buttonVariants({ variant: "outline" }), "h-11 w-full")}>
                        {t("cart.btn_checkout")}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CartNotification;
